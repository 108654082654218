
import _ from 'lodash';
import { enqueueSnackbar } from 'notistack';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Box, Stack, useMediaQuery } from '@mui/system';
import { Card, Link, MenuItem, useTheme, IconButton, Typography, CardContent } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { OrgCandidate, CandidateAvailabilityStatus } from 'src/services/candidates/candidates.types';

import Iconify from 'src/components/iconify';
import DateDisplay from 'src/components/date/date-display';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import FeatureLockedChip from 'src/components/billing/feature-locked-chip';
import AvailabilityStatusLabel from 'src/components/candidates/availability-status-label';

import { TenantType } from 'src/types/enums';

type Props = {
  row: OrgCandidate,
  onAddToJob?: (candidateId: string) => void;
  onExportProfile?: (candidateId: string) => void;
};

export function CandidateTableRow({ row, onAddToJob, onExportProfile }: Props) {

  const popover = usePopover();

  const { t } = useTranslate();

  const theme = useTheme();

  const router = useRouter();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const showNotesModal = useBoolean();

  const tenantType = useOrgTenant();

  const confirm = useBoolean();

  const viewDetail = () => {
    router.push(`${paths.dashboard.candidates.root}/${row.id}`);
  };

  const handleCopyToClipboard = (text: string) => {
    popover.onClose();

    if (!text) return;

    navigator.clipboard.writeText(text ?? "" as string);
    enqueueSnackbar(t('common.copied_to_clipboard'), { variant: 'success' });
  }


  if (isMobile) {
    return (
      <Card sx={{ mx: 2, mb: 1 }} onClick={viewDetail}>
        <CardContent>
          <Stack direction='column' alignItems="start" justifyContent='center'>
            <Typography variant="h6">
              {row.candidate_profile.first_name} {row.candidate_profile.last_name}
            </Typography>
            <Typography variant="caption" gutterBottom>
              {!_.isEmpty(row.candidate_profile.position_title) ? row.candidate_profile.position_title : t('candidates.table.no_position_title')}
            </Typography>
          </Stack>


          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {

              tenantType === TenantType.Recruiter && (
                <>
                  {
                    row.candidate_profile.availability_status ?
                      <Stack direction='column' alignItems="start" justifyContent='center' gap={.5}>
                        <Box>
                          <AvailabilityStatusLabel status={row.candidate_profile.availability_status} />
                        </Box>
                        {
                          (row.candidate_profile.availability_status === CandidateAvailabilityStatus.AVAILABLE_SOON && row.candidate_profile.available_from) && (
                            <Stack direction='row' alignItems='center' gap={.5}>
                              (<DateDisplay variant='caption' date={row.candidate_profile.available_from} format='Do of MMM YYYY' />)
                            </Stack>
                          )
                        }
                      </Stack>
                      :
                      <FeatureLockedChip text={t('candidates.table.availability')} />
                  }
                </>
              )
            }

            <Stack direction='row' alignItems="center" justifyContent='start' gap={.5}>
              <Iconify icon="bitcoin-icons:refresh-filled" width={15} height={15} />
              <DateDisplay variant='caption' date={row.candidate_profile.updated_at} />
            </Stack>
          </Stack>

          <IconButton
            sx={{ position: 'absolute', top: 15, right: 8, zIndex: 50 }}
            color={popover.open ? 'primary' : 'default'}
            onClick={popover.onOpen}
          >
            <Iconify icon="eva:more-horizontal-fill" />
          </IconButton>
        </CardContent>

        <CustomPopover
          open={popover.open}
          onClose={popover.onClose}
          arrow="right-top"
          sx={{ width: 200 }}
        >
          <MenuItem
            onClick={() => {
              popover.onClose();
              viewDetail();
            }}
          >
            <Iconify icon="solar:eye-bold" />
            {t('common.view')}
          </MenuItem>

          {
            onAddToJob &&
            <MenuItem
              onClick={() => onAddToJob(row.id)}
            >
              <Iconify icon="mdi:invite" />
              {t('common.add_to_job')}
            </MenuItem>
          }

          {
            onExportProfile &&
            <MenuItem
              onClick={() => onExportProfile(row.id)}
            >
              <Iconify icon="bx:export" />
              {t('common.export_profile')}
            </MenuItem>
          }

          {
            row.candidate_profile?.email && (
              <MenuItem onClick={() => handleCopyToClipboard(row.candidate_profile?.email as string)}>
                <Iconify icon="mdi:email-outline" />
                <Typography variant='subtitle2'>{t('candidates.detail.actions.copy_email')}</Typography>
              </MenuItem>
            )
          }

          {
            row.candidate_profile?.phone && (
              <MenuItem onClick={() => handleCopyToClipboard(row.candidate_profile?.phone as string)}>
                <Iconify icon="solar:phone-linear" />
                <Typography variant='subtitle2'>{t('candidates.detail.actions.copy_phone')}</Typography>
              </MenuItem>
            )
          }
        </CustomPopover>

      </Card>
    );
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <Stack direction='column' alignItems="start" justifyContent='center'>
            <Link variant='body2' sx={{ color: theme.palette.text.primary, fontWeight: 600, cursor: 'pointer' }} onClick={viewDetail}>
              {row.candidate_profile.first_name} {row.candidate_profile.last_name}
            </Link>
            <Typography variant="caption" gutterBottom>
              {!_.isEmpty(row.candidate_profile.position_title) ? row.candidate_profile.position_title : t('candidates.table.no_position_title')}
            </Typography>
          </Stack>

        </TableCell>

        <TableCell>
          {
            row.candidate_profile.email ?
              <Link variant='body2' underline='hover' color="InfoText" href={`mailto:${row.candidate_profile?.email}`}>
                {row.candidate_profile.email}
              </Link>
              :
              <Typography variant='body2' color='textSecondary'>
                {t('common.via_recruiter')}
              </Typography>
          }

        </TableCell>

        {
          tenantType === TenantType.Recruiter && (
            <TableCell>
              {
                row.candidate_profile.availability_status ?
                  <Stack direction='column' alignItems="start" justifyContent='center' gap={.5}>
                    <Box>
                      <AvailabilityStatusLabel status={row.candidate_profile.availability_status} />
                    </Box>
                    {
                      (row.candidate_profile.availability_status === CandidateAvailabilityStatus.AVAILABLE_SOON && row.candidate_profile.available_from) && (
                        <Stack direction='row' alignItems='center' gap={.5}>
                          <Typography variant='caption'>
                            {t('candidates.table.available_on')}
                          </Typography>
                          <DateDisplay variant='caption' date={row.candidate_profile.available_from} format='Do of MMM YYYY' />
                        </Stack>
                      )
                    }
                  </Stack>
                  :
                  <FeatureLockedChip text={t('candidates.table.availability')} />
              }
            </TableCell>
          )
        }

        <TableCell>
          <DateDisplay variant='caption' date={row.candidate_profile.updated_at} />
        </TableCell>

        <TableCell align="right" sx={{ pr: 1 }}>
          <IconButton
            color={popover.open ? 'inherit' : 'default'}
            onClick={popover.onOpen}
          >
            <Iconify icon="eva:more-horizontal-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 200 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            viewDetail();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          {t('common.view')}
        </MenuItem>

        {
          onAddToJob &&
          <MenuItem
            onClick={() => onAddToJob(row.id)}
          >
            <Iconify icon="mdi:invite" />
            {t('common.add_to_job')}
          </MenuItem>
        }


        {
          onExportProfile &&
          <MenuItem
            onClick={() => onExportProfile(row.id)}
          >
            <Iconify icon="bx:export" />
            {t('common.export_profile')}
          </MenuItem>
        }

        {
          row.candidate_profile?.email && (
            <MenuItem onClick={() => handleCopyToClipboard(row.candidate_profile?.email as string)}>
              <Iconify icon="mdi:email-outline" />
              <Typography variant='subtitle2'>{t('candidates.detail.actions.copy_email')}</Typography>
            </MenuItem>
          )
        }

        {
          row.candidate_profile?.phone && (
            <MenuItem onClick={() => handleCopyToClipboard(row.candidate_profile?.phone as string)}>
              <Iconify icon="solar:phone-linear" />
              <Typography variant='subtitle2'>{t('candidates.detail.actions.copy_phone')}</Typography>
            </MenuItem>
          )
        }

      </CustomPopover>
    </>
  );
}
