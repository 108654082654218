import { t } from 'i18next';
import { useMemo, MouseEventHandler } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { isMobile } from 'src/utils/user-agent';

import { useAppDispatch } from 'src/store/store';
import { AuthContext } from 'src/services/auth/auth.types';
import { Product } from 'src/services/billing/billing.types';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useOrgHierarchy } from 'src/auth/hooks/useOrgHierarchy';
import { getActiveProduct } from 'src/services/billing/billing.utils';
import { UserRole } from 'src/services/organisation/organisation.enum';
import { useGetProfileAvailabilityQuery } from 'src/services/candidates/candidates.service';
import { setAvailabilityManagerModalVisibility } from 'src/store/slices/candidate/candidateSlice';
import { getOrgTenant, Business_Tenants, isOrganisationTenant } from 'src/services/auth/auth.utils';
import {
  useGetProductsQuery,
  useGetMySubscriptionQuery,
} from 'src/services/billing/billing.services';
import {
  useGetMyAccountProfileQuery,
  useUpdateOnboardingContextMutation,
} from 'src/services/account/account.service';

import Label from 'src/components/label';
import AvailabilityStatusLabel from 'src/components/candidates/availability-status-label';

import ConfirmSubscriptionDialog from 'src/sections/admin/billing/payment/payment-confirm-subscription-dialog';

import { TenantType, TenantHierarchyLevel } from 'src/types/enums';

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const authContext = useAuthContext();
  const { user, account } = authContext as AuthContext;

  const router = useRouter();

  const dispatch = useAppDispatch();

  const tenantHierarchyLevel = useOrgHierarchy();

  const [updateOnboardingContext] = useUpdateOnboardingContextMutation();

  const { currentData: activeSubscription, isError, status } = useGetMySubscriptionQuery();

  const { currentData: availableProducts } = useGetProductsQuery();

  const { currentData: profileData } = useGetMyAccountProfileQuery();

  const tenantType = getOrgTenant(authContext);

  const { currentData: candidateAvailability } = useGetProfileAvailabilityQuery(undefined, {
    skip: tenantType !== TenantType.Candidate
  });

  const tenantProducts = useMemo(() => {
    if (!availableProducts) return;

    // eslint-disable-next-line consistent-return
    return availableProducts.filter((product) => product.tenant_type === tenantType);
  }, [availableProducts, tenantType]);

  const currentProduct = useMemo<Product | undefined>(() => {
    if (!tenantProducts) return;

    if (!activeSubscription || isError) {
      // Free Tier
      // eslint-disable-next-line consistent-return
      return tenantProducts.find((product) => !product.subscription_required);
    }

    // eslint-disable-next-line consistent-return
    return getActiveProduct(activeSubscription, tenantProducts);

  }, [activeSubscription, tenantProducts, isError]);

  const showUpgradeModal = useBoolean();

  const showUpgradeLabels = useMemo(
    () => Business_Tenants.includes(tenantType) && account.org_role === UserRole.Admin && tenantHierarchyLevel === TenantHierarchyLevel.PARENT,
    [account.org_role, tenantType, tenantHierarchyLevel]
  );

  const onTakeTourClicked = async () => {
    try {
      await updateOnboardingContext({
        signup_flow: false,
      }).unwrap();

      router.push(paths.dashboard.root);
    } catch (e) {
      console.error(e);
    }
  };

  const handleOpenAvailabilityManagerModal = () => {
    dispatch(setAvailabilityManagerModalVisibility({ status: true }));
  }

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Avatar
            src={profileData?.profile_image?.public_path}
            alt={user?.name}
            sx={{ width: 48, height: 48 }}
          >
            {user?.name?.charAt(0).toUpperCase()}
          </Avatar>

          {showUpgradeLabels && !currentProduct?.subscription_required && (
            <Label
              color="success"
              variant="filled"
              sx={{
                top: -6,
                px: 0.5,
                left: 40,
                height: 20,
                position: 'absolute',
                borderBottomLeftRadius: 2,
              }}
            >
              {t('common.free')}
            </Label>
          )}
        </Box>

        <Stack spacing={0} sx={{ mb: 2, mt: 1.5, width: 1 }}>

          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {user?.email}
          </Typography>

          {
            tenantType === TenantType.Candidate && (
              <Box sx={{
                my: 1,
                width: '100%',
                px: 2
              }} >
                <AvailabilityStatusLabel status={candidateAvailability?.status} enableTooltip={false} onClick={handleOpenAvailabilityManagerModal as MouseEventHandler<HTMLDivElement>} />
              </Box>
            )
          }
        </Stack>

        <Stack spacing={1} direction="column" alignItems="center" justifyContent="center">
          {showUpgradeLabels && !currentProduct?.subscription_required && (
            <Button variant="contained" rel="noopener" onClick={() => showUpgradeModal.onTrue()}>
              {t('billing.subscription.upgrade')}
            </Button>
          )}

          {!isMobile() && (
            <Button
              variant={isOrganisationTenant(tenantType) ? 'text' : 'contained'}
              onClick={onTakeTourClicked}
            >
              {t('joyride.take_tour')}
            </Button>
          )}
        </Stack>
      </Stack>

      <ConfirmSubscriptionDialog
        chosenProduct={currentProduct}
        open={showUpgradeModal.value}
        onClose={showUpgradeModal.onFalse}
      />
    </Stack>
  );
}
