import { useState, useCallback } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Stack,
  Button,
  Checkbox,
  TextField,
  Typography,
  Autocomplete,
  AutocompleteRenderOptionState
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { DocumentType } from 'src/services/documents/documents.types';
import { CandidateProfileDocument } from 'src/services/candidates/candidates.types';
import {
  useGetProfileDocumentsQuery
} from 'src/services/candidates/candidates.service';

import UploadCandidateDocumentModal from 'src/sections/profile/modals/upload-document-modal';

type DocumentSelectorProps = {
  type: DocumentType;
  profileId: string;
  label?: string;
  enableUpload?: boolean;
  onSelect: (type: DocumentType, document: CandidateProfileDocument | null) => void;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function ProfileDocumentSelector({ type, onSelect, profileId, enableUpload = true, label }: DocumentSelectorProps) {
  const { t } = useTranslate();

  const [searchQuery, setSearchQuery] = useState<string>('');

  const showUploadModal = useBoolean();

  const { currentData: myDocuments } = useGetProfileDocumentsQuery({
    profileId,
    params: {
      page: 1,
      per_page: 5,
      search_query: searchQuery,
      type,
    },
  });

  const [selectedDocument, setSelectedDocument] = useState<CandidateProfileDocument | null>(null);

  const onDocumentAttached = (profileDoc: any) => {
    setSelectedDocument(profileDoc);
    onSelect(profileDoc.document.type, profileDoc);
    showUploadModal.onFalse();
  }

  const renderOption = useCallback((props: React.HTMLAttributes<HTMLLIElement> & { key: any }, option: CandidateProfileDocument, { selected }: AutocompleteRenderOptionState) => (
    <li {...props}>
      <Stack direction="row">
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selectedDocument?.id === option.id}
          disabled={type === option.document.type}
        />
        <Stack direction="column">
          <Typography variant="body1">{option.document.name}</Typography>
          <Typography variant="caption" color="text.secondary">
            {t(`enums.document_type.${option.document.type}`)}
          </Typography>
        </Stack>
      </Stack>
    </li>
  ), [type, t, selectedDocument]);

  return (
    <Stack direction="row" alignItems="center" gap={1} width="100%">
      {
        enableUpload && (
          <Button variant="text" onClick={showUploadModal.onTrue}>
            {t('common.upload')} {t('common.new')}
          </Button>
        )
      }

      <Autocomplete
        sx={{
          minWidth: 200,
          flexGrow: 1
        }}
        value={selectedDocument}
        disableCloseOnSelect
        onInputChange={(_e, value) => setSearchQuery(value)}
        onChange={(event, newValue) => {
          setSelectedDocument(newValue);
          onSelect(type, newValue ?? null);
        }}
        options={myDocuments?.results ?? []}
        getOptionLabel={(option: CandidateProfileDocument) => option.document.name as string}
        getOptionDisabled={(option) => type !== option.document.type}
        renderOption={renderOption}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ?? t('applications.resource_requests.action.select_documents')}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off'
            }}
          />
        )}
      />

      {
        showUploadModal.value && (
          <UploadCandidateDocumentModal
            open={showUploadModal.value}
            onClose={showUploadModal.onFalse}
            profileId={profileId}
            type={type}
            onUpload={onDocumentAttached}
          />
        )
      }
    </Stack>
  );
}