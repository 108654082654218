
import { Tooltip, useTheme, useMediaQuery } from '@mui/material';

import { useTranslate } from 'src/locales';
import { JobStatus } from 'src/services/jobs/jobs.types';

import Iconify from 'src/components/iconify';

export function JobStatusIcon({ status }: { status: JobStatus }) {

  const { t } = useTranslate();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderJobStatus = () => {
    if (status === JobStatus.UNAPPROVED) {
      return (
        <Tooltip title={t('jobs.status_tooltips.unapproved')}>
          <Iconify icon="octicon:unverified-16" color="gray" width={isMobile ? 16 : 20} height={isMobile ? 16 : 20} />
        </Tooltip>
      );
    }

    if (status === JobStatus.ACTIVE) {
      return (
        <Tooltip title={t('jobs.status_tooltips.active')}>
          <Iconify icon="lucide:verified" color="green" width={isMobile ? 16 : 20} height={isMobile ? 16 : 20} />
        </Tooltip>
      );
    }

    if (status === JobStatus.CHANGES_REQUESTED) {
      return (
        <Tooltip title={t('jobs.status_tooltips.changes_requested')}>
          <Iconify icon="akar-icons:chat-edit" color="darkorange" width={isMobile ? 16 : 20} height={isMobile ? 16 : 20} />
        </Tooltip>
      );
    }

    if (status === JobStatus.DRAFT) {
      return (
        <Tooltip title={t('jobs.status_tooltips.draft')}>
          <Iconify icon="hugeicons:license-draft" color="gray" width={isMobile ? 16 : 20} height={isMobile ? 16 : 20} />
        </Tooltip>
      );
    }

    if (status === JobStatus.ARCHIVED) {
      return (
        <Tooltip title={t('jobs.status_tooltips.archived')}>
          <Iconify icon="solar:archive-check-broken" color="red" width={isMobile ? 16 : 20} height={isMobile ? 16 : 20} />
        </Tooltip>
      );
    }

    return null;
  }

  return (
    <>
      {renderJobStatus()}
    </>
  )
}
