import { useState } from 'react';
import { useParams } from 'react-router';

import { Tab, Grid, Tabs, alpha, Typography } from '@mui/material';

import { useTranslate } from 'src/locales';
import { useGetOrganizationCandidateQuery } from 'src/services/organisation/organisation.service';

import ProfileUser from 'src/sections/profile/profile-sections/profile-user';
import CandidateJobsList from 'src/sections/candidates/jobs/jobs-table-view';
import CandidateProfileEditor from 'src/sections/profile/profile-sections/profile-editor';

// ----------------------------------------------------------------------

export default function CandidateDetail() {
  const [selectedTab, onSelectTab] = useState<'profile' | 'jobs'>('profile');

  const { t } = useTranslate();

  const { candidate_id } = useParams();

  const { currentData: orgCandidate, isLoading } = useGetOrganizationCandidateQuery(
    candidate_id ?? '',
    {
      skip: !candidate_id,
    }
  );

  return (
    <Grid container gap={3}>
      <Grid item xs={12}>
        <ProfileUser profile={orgCandidate?.candidate_profile} isLoading={isLoading} mode="view" />
      </Grid>

      <Grid item xs={12}>
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => onSelectTab(newValue)}
          sx={{
            px: 2.5,
            mb: { xs: 1, sm: 'unset' },
            boxShadow: (elTheme) => `inset 0 -2px 0 0 ${alpha(elTheme.palette.grey[500], 0.08)}`,
          }}
        >
          <Tab
            key="profile"
            iconPosition="end"
            value="profile"
            label={
              <Typography
                variant="subtitle2"
                sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
              >
                {t('candidates.detail.tabs.profile')}
              </Typography>
            }
          />
          <Tab
            key="jobs"
            iconPosition="end"
            value="jobs"
            label={
              <Typography
                variant="subtitle2"
                sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
              >
                {t('candidates.detail.tabs.jobs')}
              </Typography>
            }
          />
        </Tabs>
      </Grid>

      {selectedTab === 'profile' && (
        <Grid item xs={12}>
          <CandidateProfileEditor
            profile={orgCandidate?.candidate_profile}
            isLoading={isLoading}
            mode="view"
          />
        </Grid>
      )}

      {selectedTab === 'jobs' && (
        <Grid item xs={12}>
          <CandidateJobsList candidateId={candidate_id as string} />
        </Grid>
      )}
    </Grid>
  );
}
