import type { FileResponse } from 'src/services/utils/utils.types';
import type { ContactSource } from 'src/services/contacts/contacts.types';
import type { UpdateSetting } from 'src/services/settings/settings.types';
import type { CandidateAvailabilityStatus } from 'src/services/candidates/candidates.types';

import { TenantType } from 'src/types/enums';

import { UserRole } from './organisation.enum';
import type { PageOptions } from '../api.types';
import type { AccountStatus } from '../account/account.types';

export enum PaymentCollectionType {
  AUTOMATIC_COLLECTION = 'automatic_collection',
  INVOICED = 'invoiced'
}

export enum CompanySizeRange {
  // recruiter specific
  ONE_TWO_STAFF = '1_2',
  THREE_FIVE_STAFF = '3_5',
  SIX_TEN_STAFF = '6_10',
  ELEVEN_TWENTY_STAFF = '11_20',
  TWENTY_ONE_AND_UP = '21_',

  // client specific
  LESS_THAN_50 = '_50',
  FIFTY_ONE_TO_100 = '51_100',
  ONE_HUNDRED_AND_ONE_TO_TWO_HUNDRED = '101_200',
  TWO_HUNDRED_AND_ONE_TO_FIVE_HUNDRED = '201_500',
  FIVE_HUNDRED_AND_ONE_AND_UP = '501_',
}

export interface SetupOrganisationDTO {
  company_name: string;
  company_information: string;
  country_code: string;
  company_size: CompanySizeRange;
  tax_id: string;
}

export interface GetDepartmentQuery {
  department_id: string;
  org_id: string;
}

export type RemoveAccountsProps = GetDepartmentQuery & {
  accounts: AccountIDObj[];
};

export interface AccountIDObj {
  account_id: string;
}

export type AccountAndRoleObj = AccountIDObj & {
  department_role: string;
};

export type AddDepartmetAccountProps = GetDepartmentQuery & {
  accounts: AccountAndRoleObj[];
};

export type AddContactProps = {
  org_id: string;
  manual_contact?: {
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
    company: string;
    type: TenantType;
  }
  account?: string;
  source: ContactSource
};

export interface RemoveContactProps {
  contact_id: string;
}

export interface EditCompanyProfileRequestProps {
  company_profile: {
    company_name: string;
    company_information?: string | null;
    company_logo_id?: string | null;
    tax_id?: string | null;
    company_size?: CompanySizeRange | null;
    country_code?: string | null;
  };
  org_id: string;
}

export interface GetDepartmentsQuery {
  page: PageOptions;
  org_id: string;
  search_query?: string;
}

export interface CreateDepartmentRequestProps {
  department: Omit<Department, 'id'>;
  org_id: string;
}

export interface EditDepartmentRequestProps {
  department: Omit<Department, 'id'>;
  org_id: string;
  department_id: string;
}

export interface DeleteDepartmentRequestProps {
  org_id: string;
  department_id: string;
}

export interface Department {
  id: string;
  name: string;
  description?: string;
  location?: string;
}

export interface GetDomainsQuery {
  page: PageOptions;
  org_id: string;
  search_query?: string;
}

export interface User {
  department_role?: UserRole;
  id?: string;
  org_role?: UserRole;
  email?: string;
  email_verified?: boolean;
  first_name: string;
  last_name: string;
  timezone?: string;
  phone?: string;
  phone_verified?: boolean;
  status?: AccountStatus;
  profile_image?: {
    id: string;
    public_path: string;
  };
  type: TenantType;
}

export interface GetUsersProps {
  page: PageOptions;
  org_id: string;
  search_query?: string;
}

export interface GetPendingInvitesProps {
  page: PageOptions;
  search_query?: string;
  org_id: string;
}

export interface InviteUserDTO {
  org_id: string;
  invites: UserInvite[];
}

export interface RemoveInviteDTO {
  org_id: string;
  invite_id: string;
}

export interface UpdateUserRoleProps {
  org_id: string;
  org_role: string;
  account_id: string;
}

export interface UserInvite {
  email: string;
  role: string;
  departments?: string[];
  default_department_role?: string;
}

export interface InviteUsersResponse {
  email: string;
  result: 'success' | 'error';
  error: string | null;
}

export interface Invite {
  id: string;
  email: string;
  status: InviteStatus;
  tenant_type: TenantType;
  role: string;
  invite_url: string;
  created_at: string;
}

export enum InviteStatus {
  Pending = 'pending',
  Expired = 'expired',
}

export interface CompanyDomain {
  id: string;
  domain: string;
  verified: boolean;
}


export interface GetOrgContactsQuery {
  org_id: string;
  page: PageOptions;
  search_query?: string;
  contact_type?: TenantType;
  linked_contacts?: boolean;
}

export interface GetOrgCandidatesQuery {
  page: PageOptions;
  search_query?: string;
  skills_query?: string;
  availability_status?: CandidateAvailabilityStatus;
}

export type UpdateSettingProps = {
  settings: UpdateSetting[];
  organizationId: string;
};

export type UpdateBrandSettingProps = {
  primary_color: string;
  secondary_color: string;
  brand_logo_id?: string | null;
  organizationId: string;
};

export interface IOrgBrandSettings {
  primary_color: string;
  secondary_color: string;
  brand_logo: FileResponse;
}

export interface WeekDaySetting {
  day: number;
  start: number;
  end: number;
}

export interface CustomDateSetting {
  date: Date;
  name: string;
}

export interface DateSettingsResponse {
  weekday_settings: WeekDaySetting[];
  custom_date_settings: CustomDateSetting[];
}

export type UpdateOrgDateSettingProps = {
  weekday_settings: WeekDaySetting[];
  custom_date_settings: CustomDateSetting[];
  organizationId: string;
};

export type ActivateSubTenantProps = {
  accounts: { id: string, role: string; }[];
};

export type MigrateAccountDto = {
  account_id: string;
  role: string;
  departments?: string[];
  default_department_role?: string;
};

export enum OrganizationStatus {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
}