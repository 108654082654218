import { TenantType } from 'src/types/enums';

import { rootApi } from '../rootApi';
import { PageOptions, APIResponse, HTTPRequestType, PaginatedResponse } from '../api.types';
import { Language, UsageTerm, SystemDocument, SystemAttribute, IBackgroundTask, BackgroundTaskStatus, TermsOfServiceResponse, TypeDefinitionsResponse, CreateSystemDocumentDto } from './system.types';

export interface RequestTermsOfServiceParams {
  type: string;
  target?: TenantType;
  region?: string;
}

export const systemApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({

    getBackgroundTasks: builder.query<IBackgroundTask[], { status?: BackgroundTaskStatus[] }>({
      query: (options) => ({
        url: '/system/tasks',
        method: HTTPRequestType.GET,
        params: options
      }),
      providesTags: ['BackgroundTasks']
    }),

    getTenantTerms: builder.query<TermsOfServiceResponse, RequestTermsOfServiceParams>({
      query: (params: RequestTermsOfServiceParams) => ({
        url: `/system/user-terms/current?type=${params.type}${params.region ? `&region=${params.region}` : ''
          }${params.target ? `&target=${params.target}` : ''}`,
        method: HTTPRequestType.GET,
      }),
    }),
    getTypeDefinitions: builder.query<TypeDefinitionsResponse, void>({
      query: () => ({
        url: '/system/type-definitions/enums',
        method: HTTPRequestType.GET,
      }),
    }),

    getLanguages: builder.query<Language[], void>({
      query: () => ({
        url: '/languages',
      }),
    }),

    getKnowledgeBaseLink: builder.query<string, void>({
      query: () => ({
        url: '/system/support/knowledgebase-link',
        method: HTTPRequestType.GET,
      }),
    }),

    getSystemSettings: builder.query<SystemAttribute[], void>({
      query: () => ({
        url: '/system/settings',
        method: HTTPRequestType.GET,
      }),
      providesTags: ['SystemSettings']
    }),
    updateSystemSettings: builder.mutation<void, Pick<SystemAttribute, "key" | "value">[]>({
      query: (data) => ({
        url: '/system/settings',
        method: HTTPRequestType.PUT,
        data,
      }),
      invalidatesTags: ['SystemSettings']
    }),

    // terms
    getSystemTerms: builder.query<PaginatedResponse<UsageTerm>, PageOptions>({
      query: (params) => ({
        url: '/system/user-terms',
        method: HTTPRequestType.GET,
        params
      }),
      providesTags: ['SystemTerms']
    }),
    getTermById: builder.query<UsageTerm, { termId: string }>({
      query: ({ termId }) => ({
        url: `/system/user-terms/${termId}`,
        method: HTTPRequestType.GET,
      }),
      providesTags: (result, error, { termId }) => [{ type: 'SystemTerms', id: termId }]
    }),
    updateTerm: builder.mutation<APIResponse, { termId: string, content: string }>({
      query: ({ termId, content }) => ({
        url: `/system/user-terms/${termId}`,
        method: HTTPRequestType.PUT,
        data: {
          content
        }
      }),
      invalidatesTags: (result, error, { termId }) => [{ type: 'SystemTerms', id: termId }, 'SystemTerms']
    }),
    setTermAsActive: builder.mutation<APIResponse, { termId: string }>({
      query: ({ termId }) => ({
        url: `/system/user-terms/${termId}/set-as-active`,
        method: HTTPRequestType.POST
      }),
      invalidatesTags: (result, error, { termId }) => [{ type: 'SystemTerms', id: termId }, 'SystemTerms']
    }),

    getSystemDocuments: builder.query<PaginatedResponse<SystemDocument>, PageOptions>({
      query: (params: PageOptions) => ({
        url: `/system/documents`,
        method: HTTPRequestType.GET,
        params
      }),
      providesTags: ['SystemDocuments']
    }),
    createSystemDocument: builder.mutation<APIResponse, CreateSystemDocumentDto>({
      query: (data) => ({
        url: `/system/documents`,
        method: HTTPRequestType.POST,
        data
      }),
      invalidatesTags: ['SystemDocuments']
    }),
    deleteSystemDocument: builder.mutation<APIResponse, { documentId: string }>({
      query: ({ documentId }) => ({
        url: `/system/documents/${documentId}`,
        method: HTTPRequestType.DELETE
      }),
      invalidatesTags: ['SystemDocuments']
    }),

    authenticateSocket: builder.mutation<APIResponse, void>({
      query: () => ({
        url: `/realtime/auth`,
        method: HTTPRequestType.POST
      })
    }),

  }),
});

export const {
  useGetBackgroundTasksQuery,

  useGetTenantTermsQuery,
  useGetTypeDefinitionsQuery,
  useGetLanguagesQuery,
  useLazyGetKnowledgeBaseLinkQuery,

  useGetSystemSettingsQuery,
  useUpdateSystemSettingsMutation,

  useGetSystemTermsQuery,
  useGetTermByIdQuery,
  useUpdateTermMutation,
  useSetTermAsActiveMutation,

  useAuthenticateSocketMutation,

  useGetSystemDocumentsQuery,
  useCreateSystemDocumentMutation,
  useDeleteSystemDocumentMutation,

} =
  systemApi;
