
import { Stack } from '@mui/system';
import { Card } from '@mui/material';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';

import { useFeatures } from 'src/sections/billing/usage/hooks/use-features';
import BrandSettingsCard from 'src/sections/admin/organization/settings/components/brand-settings';
import BusinessSettingsCard from 'src/sections/admin/organization/settings/components/business-settings';

import { TenantType } from 'src/types/enums';
import { FeatureType } from 'src/types/subscription.types';

import JobBoardCard from '../components/job-board-card';
import SubTenantCard from '../components/sub-tenant-card';
import WorkingHoursComponent from '../components/working-hour-selector';

export default function OrganizationSettingsView() {
  const { t } = useTranslate();

  const tenantType = useOrgTenant();

  const { canAccess } = useFeatures();

  return (
    <Stack spacing={2}>

      {tenantType === TenantType.Recruiter && (
        <BusinessSettingsCard />
      )}

      <BrandSettingsCard />

      {tenantType === TenantType.Recruiter && (
        <Card>
          <WorkingHoursComponent />
        </Card>
      )}

      {
        canAccess(FeatureType.Sub_Organization_Enabled) && (
          <SubTenantCard />
        )
      }

      {
        [TenantType.Recruiter, TenantType.Client].includes(tenantType) && (
          <JobBoardCard />
        )
      }

    </Stack>
  );
}
