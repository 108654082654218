

import { useTheme } from '@mui/system';
import { Stack, IconButton } from '@mui/material';

import Iconify from 'src/components/iconify';

type Props = {
  rating: number | null;
  onSelectExcitementRating?: (rating: number) => void;
  starSize?: number;
};

export default function StarRating({ rating, starSize = 20, onSelectExcitementRating }: Props) {

  const theme = useTheme();

  return (
    <Stack direction="row" justifyContent="flex-start" gap={0.5} sx={{ '&:hover': onSelectExcitementRating ? { cursor: 'pointer' as const } : {} }}>
      <IconButton disabled={!onSelectExcitementRating} onClick={() => onSelectExcitementRating?.(1)} sx={{ padding: 0 }}>
        <Iconify
          sx={{
            width: starSize,
            height: starSize,
            color:
              (rating ?? 0) >= 1 ? '#E5B80B' : theme.palette.text.disabled,
            ':hover': {
              color: '#E5B80B',
            },
          }}
          icon={`solar:star-${(rating ?? 0) >= 1 ? 'bold' : 'line-duotone'}`}
        />
      </IconButton>
      <IconButton disabled={!onSelectExcitementRating} onClick={() => onSelectExcitementRating?.(2)} sx={{ padding: 0 }}>
        <Iconify
          sx={{
            width: starSize,
            height: starSize,
            color:
              (rating ?? 0) >= 2 ? '#E5B80B' : theme.palette.text.disabled,
            ':hover': {
              color: '#E5B80B',
            },
          }}
          icon={`solar:star-${(rating ?? 0) >= 2 ? 'bold' : 'line-duotone'}`}
        />
      </IconButton>
      <IconButton disabled={!onSelectExcitementRating} onClick={() => onSelectExcitementRating?.(3)} sx={{ padding: 0 }}>
        <Iconify
          sx={{
            width: starSize,
            height: starSize,
            color:
              (rating ?? 0) >= 3 ? '#E5B80B' : theme.palette.text.disabled,
            ':hover': {
              color: '#E5B80B',
            },
          }}
          icon={`solar:star-${(rating ?? 0) >= 3 ? 'bold' : 'line-duotone'}`}
        />
      </IconButton>
      <IconButton disabled={!onSelectExcitementRating} onClick={() => onSelectExcitementRating?.(4)} sx={{ padding: 0 }}>
        <Iconify
          sx={{
            width: starSize,
            height: starSize,
            color:
              (rating ?? 0) >= 4 ? '#E5B80B' : theme.palette.text.disabled,
            ':hover': {
              color: '#E5B80B',
            },
          }}
          icon={`solar:star-${(rating ?? 0) >= 4 ? 'bold' : 'line-duotone'}`}
        />
      </IconButton>
      <IconButton disabled={!onSelectExcitementRating} onClick={() => onSelectExcitementRating?.(5)} sx={{ padding: 0 }}>
        <Iconify
          sx={{
            width: starSize,
            height: starSize,
            color:
              (rating ?? 0) >= 5 ? '#E5B80B' : theme.palette.text.disabled,
            ':hover': {
              color: '#E5B80B',
            },
          }}
          icon={`solar:star-${(rating ?? 0) >= 5 ? 'bold' : 'line-duotone'}`}
        />
      </IconButton>
    </Stack>
  );
}
