import { useMemo, useState } from 'react';
import { enqueueSnackbar } from 'notistack';

import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Card, Chip, Alert, Stack, Button, Dialog, useTheme, CardHeader, Typography, CardContent, DialogTitle, DialogActions, DialogContent, useMediaQuery } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import i18n from 'src/locales/i18n';
import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useActivateSubTenantMutation } from 'src/services/organisation/organisation.service';

import Iconify from 'src/components/iconify';

const getActivateError = (error: any) => i18n.t('admin-organization.sub-tenant.activate.api.default_error')

export default function SubTenantCard() {

  const { t } = useTranslate();
  const theme = useTheme();
  const confirmControl = useBoolean();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [activationError, setActivationError] = useState<string | null>();

  const authContext = useAuthContext();

  const [activateSubTenant, { isLoading: isActivating }] = useActivateSubTenantMutation();

  const isActivated = useMemo(() => authContext.organization?.sub_tenant_activated, [authContext]);

  const handleActivate = async () => {
    try {
      await activateSubTenant({
        accounts: []
      }).unwrap();

      enqueueSnackbar(t('admin-organization.sub-tenant.activate.api.success'), { variant: 'success' });

      confirmControl.onFalse();
    }
    catch (error) {
      const errorMsg = getActivateError(error);

      enqueueSnackbar(errorMsg, { variant: 'error' });
      setActivationError(errorMsg);
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Card
        sx={{
          width: "100%",
          position: 'relative',
          '&:hover': {
            boxShadow: theme.shadows[4],
          },
        }}
      >
        <CardHeader
          avatar={
            <Iconify
              icon="material-symbols:add-business-outline-rounded"
              sx={{
                width: 30,
                height: 30,
                color: theme.palette.primary.main,
              }}
            />
          }
          title={
            <Typography variant="h6" lineHeight={1.75}>
              {t('admin-organization.sub-tenant.title')}
            </Typography>
          }
          action={
            isActivated && (
              <Chip
                icon={<CheckCircleIcon sx={{ width: 20 }} />}
                label={t('Activated')}
                color="success"
                sx={{ height: 32 }}
              />
            )
          }
        />

        <CardContent>
          <Stack spacing={3}>
            <Typography color="text.secondary" variant='body2'>
              {t(
                'admin-organization.sub-tenant.activate-desc'
              )}
            </Typography>

            {!isActivated && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => confirmControl.onTrue()}
                sx={{ alignSelf: isMobile ? 'stretch' : 'flex-start' }}
              >
                {t('admin-organization.sub-tenant.activate-action')}
              </Button>
            )}

            {isActivated && (
              <Alert severity="info">
                {t(
                  'admin-organization.sub-tenant.active-desc'
                )}
              </Alert>
            )}
          </Stack>
        </CardContent>
      </Card>

      <Dialog open={confirmControl.value} onClose={confirmControl.onFalse}>
        <DialogTitle>{t('admin-organization.sub-tenant.activate.title')}</DialogTitle>
        <DialogContent>
          <Typography variant='body2'>
            {t(
              'admin-organization.sub-tenant.activate.confirm'
            )}
          </Typography>

          {
            activationError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {activationError}
              </Alert>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmControl.onFalse}>{t('common.cancel')}</Button>
          <LoadingButton loading={isActivating} disabled={isActivating} onClick={handleActivate} variant="contained" color="primary">
            {t('common.activate')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}