import { useMemo, ChangeEvent } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import { useMediaQuery } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { tablePaginationClasses } from '@mui/material/TablePagination';

import i18n from 'src/locales/i18n';
import { PageOptions } from 'src/services/api.types';
import { OrganisationDocument } from 'src/services/documents/documents.types';
import { CandidateProfileDocument } from 'src/services/candidates/candidates.types';

import {
  TableProps,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import FileManagerTableRow from './file-manager-table-row';
import ProfileDocumentTableRow from './candidate-profile-document-row';

// ----------------------------------------------------------------------

type Props = {
  table: TableProps;
  notFound: boolean;
  dataFiltered: OrganisationDocument[] | CandidateProfileDocument[] | undefined;
  count: number;
  pageOptions?: PageOptions;
  onDeleteRow?: (id: string) => void;
  onChangePage?: (_e: any, page: number) => void;
  onChangePerPage?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  documentType?: 'organisation' | 'candidate';
};

export default function FileManagerTable({
  table,
  notFound,
  onDeleteRow,
  count,
  dataFiltered,
  pageOptions,
  onChangePage,
  onChangePerPage,
  documentType = 'organisation',
}: Props) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const tableLabels = useMemo(
    () =>
      documentType === 'organisation'
        ? [
            { id: 'name', label: i18n.t('common.name') },
            { id: 'type', label: i18n.t('common.type'), width: 120 },
            { id: 'modifiedAt', label: i18n.t('common.modified'), width: 180 },
            { id: 'preview', label: i18n.t('common.preview'), width: 80 },
          ]
        : [
            { id: 'icon', label: i18n.t('common.preview'), width: undefined },
            { id: 'expiry_date', label: i18n.t('common.expiry'), width: 200 },
            { id: 'visibility_rule', label: i18n.t('common.visibility'), width: 120 },
            { id: 'preview', label: i18n.t('common.preview'), width: 80 },
            { id: 'popover', width: 80 },
          ],
    [documentType]
  );

  const {
    dense,
    //
    selected,
    onSelectRow,
    onChangeDense,
  } = table;

  return (
    <>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <TableContainer>
          <Table
            size={dense ? 'small' : 'medium'}
            sx={{
              borderCollapse: 'separate',
            }}
          >
            {!isMobile && (
              <TableHeadCustom headLabel={tableLabels} rowCount={dataFiltered?.length} />
            )}

            <TableBody>
              {dataFiltered?.map((row) =>
                documentType === 'organisation' ? (
                  <FileManagerTableRow
                    key={row.id}
                    row={row as OrganisationDocument}
                    selected={selected.includes(row.id)}
                    onSelectRow={() => onSelectRow(row.id)}
                  />
                ) : (
                  <ProfileDocumentTableRow
                    key={row.id}
                    row={row as CandidateProfileDocument}
                    selected={selected.includes(row.id)}
                    onSelectRow={() => onSelectRow(row.id)}
                  />
                )
              )}

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {pageOptions && onChangePage && onChangePerPage && (
        <TablePaginationCustom
          count={count}
          page={pageOptions.page}
          rowsPerPage={pageOptions.per_page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangePerPage}
          //
          dense={dense}
          onChangeDense={onChangeDense}
          sx={{
            [`& .${tablePaginationClasses.toolbar}`]: {
              borderTopColor: 'transparent',
            },
          }}
        />
      )}
    </>
  );
}
