/* eslint-disable no-useless-return */
import { useSnackbar } from 'notistack';
import { useMemo, Fragment, useState, useEffect, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Tab,
  List,
  Tabs,
  Stack,
  Dialog,
  Divider,
  ListItem,
  Pagination,
  Typography,
  DialogTitle,
  ListItemText,
  DialogContent
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { isOrganisationTenant } from 'src/services/auth/auth.utils';
import { PageOptions, PaginatedResponse } from 'src/services/api.types';
import { Offer, OfferStatus } from 'src/services/applications/applications.types';
import {
  setShowOfferModal,
  showCandidateOfferModal,
} from 'src/store/slices/applications/applicationsSlice';
import {
  useGetMyOffersQuery,
  useRescindOfferMutation,
  useGetOffersByJobIdQuery,
  useGetApplicationOffersQuery,
} from 'src/services/applications/applications.service';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import EmptyContent from 'src/components/empty-content';
import NestedMenu from 'src/components/menu/nested-menu';
import { ConfirmDialog } from 'src/components/custom-dialog';

import { TenantType } from 'src/types/enums';

// Either use applicationId or jobId to load application offers.
type Props = {
  open: boolean;
  applicationId?: string;
  jobId?: string;
  onClose: VoidFunction;
};

const DEFAULT_PAGE = {
  page: 1,
  per_page: 5,
};

const TAB_OPTIONS = ['active', 'all'];

export default function OffersListModal({ open, applicationId, jobId, onClose }: Props) {
  const { t } = useTranslate();

  const rescindControl = useBoolean();

  const [pageOptions, setPageOptions] = useState<PageOptions>(DEFAULT_PAGE);

  const [offersData, setOffersData] = useState<PaginatedResponse<Offer> | null>(null);

  const [selectedTab, setSelectedTab] = useState<string>('active');

  const [currentOffer, setCurrentOffer] = useState<Offer | null>(null);

  const dispatch = useAppDispatch();

  const tenant = useOrgTenant();

  const { enqueueSnackbar } = useSnackbar();

  const [rescindOffer, { isLoading: rescindLoading }] = useRescindOfferMutation();

  const { currentData: jobOffers } = useGetOffersByJobIdQuery(
    {
      jobId: jobId as string,
      params: {
        ...pageOptions,
      },
    },
    { skip: !jobId || tenant === TenantType.Candidate }
  );

  const { currentData: applicationOffers } = useGetApplicationOffersQuery(
    {
      applicationId: applicationId as string,
      params: {
        ...pageOptions,
      },
    },
    { skip: !applicationId || tenant === TenantType.Candidate }
  );

  const { currentData: myOffers } = useGetMyOffersQuery(pageOptions, {
    skip: !!applicationId || tenant !== TenantType.Candidate,
  });

  const confirmRescind = useCallback((offer: Offer) => {
    setCurrentOffer(offer);
    rescindControl.onTrue();
  }, [rescindControl]);

  const closeRescindConfirm = useCallback(() => {
    setCurrentOffer(null);
    rescindControl.onFalse();
  }, [rescindControl]);

  const onViewOffer = useCallback(
    (offer: Offer) => {
      if (tenant === TenantType.Candidate) {
        dispatch(showCandidateOfferModal(offer.id));

        return;
      }

      dispatch(
        setShowOfferModal({
          applicationId: offer.application_id as string,
          offerId: offer.id,
        })
      );
    },
    [dispatch, tenant]
  );

  const onRescindOffer = useCallback(
    async (offerId: string) => {
      try {
        await rescindOffer({
          offerId
        }).unwrap();

        closeRescindConfirm();
        enqueueSnackbar(t('applications.api.rescind.success'), { variant: 'success' });

      } catch (e) {
        console.error(e);

        enqueueSnackbar(t('applications.api.rescind.default_error'), { variant: 'error' });
      }
    },
    [closeRescindConfirm, enqueueSnackbar, rescindOffer, t]
  );

  const onChangePage = useCallback((value: number) => {
    setPageOptions((prev) => ({
      ...prev,
      page: value,
    }));
  }, []);

  useEffect(() => {
    if (applicationOffers) {
      setOffersData(applicationOffers);

      return;
    }

    if (myOffers) {
      setOffersData(myOffers);

      return;
    }

    if (jobOffers) {
      setOffersData(jobOffers);
      return
    }

  }, [applicationOffers, jobOffers, myOffers]);

  const onSelectTab = (_event: any, tab: 'active' | 'all') => {
    setSelectedTab(tab);
  }

  const displayedOffers = useMemo(() => {
    if (!offersData) return [];

    if (selectedTab === 'active') {
      return offersData.results.filter((offer) => [OfferStatus.SENT, OfferStatus.CHANGES_REQUESTED, OfferStatus.IN_REVIEW].includes(offer.status));
    }

    return offersData.results;
  }, [offersData, selectedTab]);

  const getMenuItems = useCallback((offer: Offer) => {
    const items = [];

    console.log(offer)

    items.push({
      label: t('common.view'),
      onClick: () => onViewOffer(offer),
      icon: <Iconify icon="solar:eye-bold" />,
    });

    if (tenant !== TenantType.Candidate && offer.status === OfferStatus.SENT) {
      items.push({
        label: t('common.rescind'),
        onClick: () => confirmRescind(offer),
        icon: <Iconify icon="hugeicons:delete-put-back" color="red" />,
        disabled: () => offer.status !== OfferStatus.SENT,
      });
    }

    return items;
  }, [confirmRescind, onViewOffer, t, tenant]);

  return (
    <Dialog sx={{ width: '100%' }} maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>{t('applications.offer_list_modal.title')}</DialogTitle>

      <DialogContent>
        <Tabs
          value={selectedTab}
          onChange={onSelectTab}
          sx={{
            px: 2,
            pb: 2,
            mt: -2
          }}
        >
          {TAB_OPTIONS.map((tab) => (
            <Tab
              key={tab}
              iconPosition="end"
              value={tab}
              label={
                <Typography
                  variant="subtitle2"
                  sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                >
                  {t(`applications.offer_list_modal.tabs.${tab}`)}
                </Typography>
              }
            />
          ))}
        </Tabs>

        <Stack direction="column" sx={{ minWidth: '600px', minHeight: 300, height: "100%", typography: 'body1' }} justifyContent="space-between">
          {
            displayedOffers.length === 0 ? (
              <EmptyContent
                filled
                title={t('applications.offer_list_modal.no_offers')}
                sx={{
                  py: 10,
                }}
              />
            ) : (
              <List sx={{ paddingBottom: 2, minWidth: 560 }}>
                {displayedOffers.map((offer) => (
                  <Fragment key={offer.id}>
                    <ListItem
                      secondaryAction={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Label variant="soft" color="info">
                            {t(`enums.offer_status.${offer.status}`)}
                          </Label>
                          <NestedMenu
                            menuItems={getMenuItems(offer)}
                            trigger={{ type: 'icon', icon: <Iconify icon="eva:more-horizontal-fill" /> }}
                          />
                        </Stack>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography onClick={() => onViewOffer(offer)} paragraph component="span" variant="subtitle2" sx={{ cursor: 'pointer', textTransform: 'capitalize', ':hover': { textDecoration: 'underline' } }}>
                            {offer.position_title}
                          </Typography>
                        }
                        secondary={
                          isOrganisationTenant(tenant) ? offer.candidate_name : offer.employer_name
                        }
                      />
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </List>
            )
          }

          <Stack flexDirection="row" justifyContent="center" sx={{ py: 2 }}>
            <Pagination
              page={pageOptions.page || 1}
              count={Math.ceil((offersData?.count || 1) / pageOptions.per_page)}
              onChange={(_e, value) => onChangePage(value)}
            />
          </Stack>
        </Stack>

        <ConfirmDialog
          open={rescindControl.value}
          onClose={closeRescindConfirm}
          title={t('common.rescind')}
          content={
            <Typography variant="body2">
              {t('applications.offer_list_modal.rescind_confirm')}
            </Typography>
          }
          action={
            <LoadingButton variant="contained" color="error" loading={rescindLoading} disabled={rescindLoading} onClick={() => onRescindOffer(currentOffer?.id as string)}>
              {t('common.rescind')}
            </LoadingButton>
          }
        />
      </DialogContent>
    </Dialog >
  );
}
