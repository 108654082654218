import { useSnackbar } from 'notistack';
import _, { add, isNumber } from 'lodash';
import { useMemo, useState, useEffect, ChangeEvent, useCallback } from 'react';

import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Alert, Button, Select, Switch, Popover, MenuItem, TextField } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { fCurrency } from 'src/utils/format-number';
import { useMixpanel } from 'src/utils/tracking/use-mixpanel';
import { TrackingEvent } from 'src/utils/tracking/tracking.types';

import { useTranslate } from 'src/locales';
import { PageOptions } from 'src/services/api.types';
import { authApi } from 'src/services/auth/auth.service';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { AuthContext } from 'src/services/auth/auth.types';
import { getOrgTenant } from 'src/services/auth/auth.utils';
import { PaymentCollectionType } from 'src/services/organisation/organisation.types';
import { Product, ProductType, ChosenProduct, PaymentMethod } from 'src/services/billing/billing.types';
import {
  getProductPrice,
  getProductTiers,
  getActiveProduct,
  getTenantFreeProduct,
  getSubscriptionErrorMessage,
} from 'src/services/billing/billing.utils';
import {
  useGetProductsQuery,
  useGetMySubscriptionQuery,
  useGetMyPaymentMethodsQuery,
  useUpgradeSubscriptionMutation,
  useActivateSubscriptionMutation,
} from 'src/services/billing/billing.services';

import Iconify from 'src/components/iconify';
import NumberInputBasic from 'src/components/number-input/quantity-input';

import { useUsageState } from 'src/sections/billing/usage';

import { FeatureType } from 'src/types/subscription.types';
import { PayPeriod, ClientPlans, RecruiterPlans } from 'src/types/payment';

import PaymentCardItem from './payment-card-item';
import PaymentNewCardDialog from './payment-new-card-dialog';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  chosenProduct?: Product;
  onToggleSubscriptionPolling?: VoidFunction;
};

const pageOptions: PageOptions = {
  page: 1,
  per_page: 4,
};

export default function ConfirmSubscriptionDialog({
  open,
  onClose,
  chosenProduct,
  onToggleSubscriptionPolling,
}: Props) {
  const { currentData: authData } = authApi.endpoints.getAuthContext.useQueryState();

  const { currentData: activeSubscription, isError } = useGetMySubscriptionQuery();

  const { currentData: availableProducts } = useGetProductsQuery();

  const { orgFeatures, featureUsage } = useUsageState();

  const [activeAddonState, setActiveAddonState] = useState<{ [key: string]: number | boolean }>({});
  const [addonState, setAddonState] = useState<{ [key: string]: number | boolean }>({});

  const { trackEvent } = useMixpanel();

  const tenantType = useOrgTenant();

  const [apiError, setApiError] = useState<string | undefined>();

  const totalSeats = useMemo(() => {
    if (!featureUsage || !orgFeatures) return 1;

    return orgFeatures.find(
      (feature) => feature.feature.type === FeatureType.Team_Seats
    )?.value as number;

  }, [featureUsage, orgFeatures]);

  const newCard = useBoolean(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate();

  useEffect(() => {
    if (!open) return;

    trackEvent(TrackingEvent.SUBSCRIPTION_MODAL_OPEN, {});
  }, [open, trackEvent]);

  const [payPeriod, setPayPeriod] = useState<PayPeriod>(
    activeSubscription?.billing_period ?? PayPeriod.Monthly
  );

  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(chosenProduct);

  const [discountCode, setDiscountCode] = useState<string | undefined>();

  const tenantProducts = useMemo(() => {
    const currentTenant = getOrgTenant(authData as AuthContext);

    return availableProducts
      ?.filter(
        (product) => product.tenant_type === currentTenant && product.type === ProductType.BASE_PLAN && product.subscription_required
      )
      .sort((productA, productB) => {
        const priceA =
          productA.prices.find((price) => price.period === PayPeriod.Yearly)?.amount ?? 0;
        const priceB =
          productB.prices.find((price) => price.period === PayPeriod.Yearly)?.amount ?? 0;

        return priceA - priceB;
      });
  }, [authData, availableProducts]);

  const isFreeProduct = useMemo(
    () => !selectedProduct?.subscription_required,
    [selectedProduct?.subscription_required]
  );

  const [seatCount, setSeatCount] = useState<number>(1);

  const activeSeatingTiers = useMemo(
    () => getProductTiers(selectedProduct, payPeriod),
    [payPeriod, selectedProduct]
  );

  const currentSeatPrice = useMemo(() => {
    const tier = activeSeatingTiers?.find(
      (priceTier) =>
        priceTier.min_quantity <= seatCount &&
        (priceTier.max_quantity >= seatCount || priceTier.max_quantity === null)
    );

    return tier?.amount ?? 0;
  }, [activeSeatingTiers, seatCount]);

  const selectedPrice = useMemo<number | undefined>(() => {
    if (!selectedProduct) return;

    // eslint-disable-next-line consistent-return
    return getProductPrice(selectedProduct, payPeriod) ?? 0;
  }, [payPeriod, selectedProduct]);

  const subscribedSeats = useMemo(() => {
    if (!activeSubscription) return 1;

    const baseProducts = availableProducts?.filter((availableProduct) => activeSubscription.products?.some((product) => product.identifier === availableProduct.identifier && availableProduct.type === ProductType.BASE_PLAN)).map((product) => {
      const baseSubProduct = activeSubscription.products?.find((subProduct) => subProduct.identifier === product.identifier);
      return { ...product, quantity: baseSubProduct?.quantity };
    }) ?? [];

    if (baseProducts?.length > 0) {
      return baseProducts?.[0]?.quantity;
    }

    return 1;
  }, [activeSubscription, availableProducts]);

  const subscribedBaseProducts = useMemo(() => {
    if (!activeSubscription) return [];

    return availableProducts?.filter((availableProduct) => activeSubscription.products?.some((product) => product.identifier === availableProduct.identifier && availableProduct.type === ProductType.BASE_PLAN)).map((product) => {
      const baseSubProduct = activeSubscription.products?.find((subProduct) => subProduct.identifier === product.identifier);
      return { ...product, quantity: baseSubProduct?.quantity };
    }) ?? [];

  }, [activeSubscription, availableProducts]);

  const availableAddOns = useMemo(() => {
    if (!availableProducts) return [];

    return availableProducts.filter((product) => product.type === ProductType.ADD_ON);
  }, [availableProducts]);

  const totalPrice = useMemo(() => {
    if (selectedPrice === null || selectedPrice === undefined) return;

    const totalSeatCost = +currentSeatPrice * seatCount;
    let addOnPrices = 0;

    // add on prices
    if (addonState && Object.keys(addonState).length > 0) {

      addOnPrices = availableAddOns.reduce((acc, addOn) => {
        if (addOn.quantity_required) {
          const quantity = addonState[addOn.identifier as string] as number;
          if (quantity) {
            const price = getProductPrice(addOn, payPeriod) ?? 0;
            return add(acc, price * quantity);
          }
          return acc;
        }

        const price = getProductPrice(addOn, payPeriod) ?? 0;
        if (addonState[addOn.identifier as string]) {
          return add(acc, price);
        }
        return acc;

      }, 0);
    }

    // eslint-disable-next-line consistent-return
    return selectedPrice + totalSeatCost + addOnPrices;
  }, [currentSeatPrice, seatCount, selectedPrice, addonState, availableAddOns, payPeriod]);

  const [cardAnchorEl, setCardAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [activateSubscription, { isLoading: isActivating }] = useActivateSubscriptionMutation();

  const [upgradeSubscription, { isLoading: isUpgrading }] = useUpgradeSubscriptionMutation();

  const { currentData: cardData } = useGetMyPaymentMethodsQuery(pageOptions);

  const [selectedCard, setSelectedCard] = useState<PaymentMethod | null>();

  const primaryCard = useMemo(() => {
    if (!cardData) return;

    // eslint-disable-next-line consistent-return
    return cardData.results.find((card) => !!card.default);
  }, [cardData]);

  useEffect(() => {
    if (!primaryCard) return;

    setSelectedCard(primaryCard);
  }, [primaryCard]);

  useEffect(() => {
    if (activeSubscription) {
      setSeatCount(subscribedSeats as number);
    }

  }, [subscribedSeats, activeSubscription]);

  useEffect(() => {
    if (activeSubscription) {

      setSeatCount(subscribedSeats as number);

      const addOnProducts = availableProducts?.filter((availableProduct) => activeSubscription.products?.some((product) => product.identifier === availableProduct.identifier && availableProduct.type === ProductType.ADD_ON)).map((product) => {
        const addOnSubProduct = activeSubscription.products?.find((subProduct) => subProduct.identifier === product.identifier);
        return { ...product, quantity: addOnSubProduct?.quantity };
      });

      const state: any = addOnProducts?.reduce((acc: any, product) => {
        if (product.quantity_required) {
          acc[product.identifier as string] = product.quantity;
          return acc;
        }

        acc[product.identifier as string] = true;
        return acc;

      }, {});

      setActiveAddonState(state);
      setAddonState(state);
    }
    else {
      const state: any = {};

      setActiveAddonState(state);
      setAddonState(state);
    }

  }, [activeSubscription, availableAddOns, subscribedSeats, availableProducts]);

  const openCardPopover = Boolean(cardAnchorEl);

  const id = open ? 'simple-popover' : undefined;

  const activeProduct = useMemo(() => {
    let currentProduct;

    if (!availableProducts) return;

    if (!activeSubscription) {
      currentProduct = getTenantFreeProduct(tenantType, availableProducts as Product[]);
    } else {
      currentProduct = getActiveProduct(activeSubscription, availableProducts as Product[]);
    }

    // eslint-disable-next-line consistent-return
    return currentProduct;

  }, [activeSubscription, availableProducts, tenantType]);

  const isDataChanged = useMemo(() => {
    if (!selectedProduct) return false;

    // console.log('activeProduct', selectedProduct.id !== activeProduct?.id);
    // console.log('activeSubscription', selectedProduct.identifier !== );
    // console.log('seatCount', seatCount !== subscribedSeats);
    // console.log('payPeriod', payPeriod !== activeSubscription?.billing_period);
    // console.log('chosenProduct', selectedProduct.id !== chosenProduct?.id);
    // console.log('selectedCard', selectedCard?.id !== primaryCard?.id);
    // console.log('addonState', !_.isEqual(addonState, activeAddonState));

    return (
      selectedProduct.id !== activeProduct?.id ||
      selectedProduct.identifier !== subscribedBaseProducts[0]?.identifier ||
      seatCount !== subscribedSeats ||
      payPeriod !== activeSubscription?.billing_period ||
      selectedProduct.id !== chosenProduct?.id ||
      selectedCard?.id !== primaryCard?.id ||
      !_.isEqual(addonState, activeAddonState)
    );
  }, [
    activeProduct,
    activeSubscription,
    chosenProduct,
    primaryCard,
    selectedCard,
    selectedProduct,
    seatCount,
    payPeriod,
    activeAddonState,
    addonState,
    subscribedSeats,
    subscribedBaseProducts
  ]);

  const handleClickCards = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCardAnchorEl(event.currentTarget);
  };

  const handleCloseCards = () => {
    setCardAnchorEl(null);
  };

  const onSelectPlan = useCallback(
    (plan: Product | undefined) => {
      setSelectedProduct(plan);

      if (plan?.max_quantity && seatCount > plan.max_quantity) {
        setSeatCount(plan.max_quantity);
      }
    },
    [seatCount]
  );

  const onSelectCard = useCallback((card: PaymentMethod) => {
    setSelectedCard(card);
    handleCloseCards();
  }, []);

  const onChangePayPeriod = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPayPeriod(event.target.checked ? PayPeriod.Yearly : PayPeriod.Monthly);
  }, []);

  const onChangeSeatCount = (count: number | null) => {
    if (!count && !isNumber(count)) return;

    setSeatCount(count);
    if (selectedProduct?.max_quantity && selectedProduct?.max_quantity < count) {
      const nextHighestPlan = tenantProducts
        ?.sort((a, b) => {
          // sort by max_quantity
          if (a.max_quantity === null) return 1;
          if (b.max_quantity === null) return -1;

          return a.max_quantity - b.max_quantity;
        })
        .find((product) =>
          product.max_quantity ? product.max_quantity >= count : product.max_quantity === null
        );

      if (nextHighestPlan) {
        setSelectedProduct(nextHighestPlan);
      } else {
        setSeatCount(selectedProduct.max_quantity);
      }
    }
  };

  const activatePlan = useCallback(async () => {
    if (!selectedProduct) return;

    setApiError(undefined);

    let promoCode;
    if (discountCode) {
      promoCode = discountCode;
    }

    let addOns: ChosenProduct[] = [];
    if (addonState && Object.keys(addonState).length > 0) {
      addOns = Object.keys(addonState).map((key: any) => ({
        identifier: key as RecruiterPlans | ClientPlans,
        quantity: addonState[key] as number,
        period: payPeriod,
      }));
    }

    try {

      await activateSubscription({
        billing_period: payPeriod,
        products: [
          {
            identifier: selectedProduct.identifier,
            period: payPeriod,
            quantity: seatCount,
          },
          ...addOns,
        ],
        promo_code: promoCode,
        payment_method_id: selectedCard?.id,
      }).unwrap();

      enqueueSnackbar(t('billing.api.subscription.upgrade.success'), { variant: 'success' });

      onClose();
    } catch (e) {
      console.error(e);

      const message = getSubscriptionErrorMessage(e);
      setApiError(message);

      enqueueSnackbar(t('billing.api.subscription.upgrade.default_error'), { variant: 'error' });
    }
  }, [
    activateSubscription,
    enqueueSnackbar,
    onClose,
    payPeriod,
    seatCount,
    discountCode,
    selectedCard?.id,
    selectedProduct,
    t,
    addonState,
  ]);

  const upgradePlan = useCallback(async () => {
    if (!selectedProduct) return;

    setApiError(undefined);

    try {

      let promoCode;
      if (discountCode) {
        promoCode = discountCode;
      }

      let addOns: ChosenProduct[] = [];
      if (addonState && Object.keys(addonState).length > 0) {
        addOns = Object.keys(addonState).map((key: any) => ({
          identifier: key as RecruiterPlans | ClientPlans,
          quantity: addonState[key] as number,
          period: payPeriod,
        }));
      }

      await upgradeSubscription({
        billing_period: payPeriod,
        products: [
          {
            identifier: selectedProduct.identifier,
            period: payPeriod,
            quantity: seatCount,
          },
          ...addOns,
        ],
        promo_code: promoCode,
        payment_method_id: selectedCard?.id,
      }).unwrap();

      enqueueSnackbar(t('billing.api.subscription.upgrade.success'), { variant: 'success' });

      onClose();

    } catch (e) {
      console.error(e);

      const message = getSubscriptionErrorMessage(e);
      setApiError(message);

      enqueueSnackbar(t('billing.api.subscription.upgrade.default_error'), { variant: 'error' });
    }
  }, [
    upgradeSubscription,
    enqueueSnackbar,
    onClose,
    payPeriod,
    seatCount,
    discountCode,
    selectedCard?.id,
    addonState,
    selectedProduct,
    t,
  ]);

  const onSubscribe = useCallback(async () => {
    if (!selectedProduct) return;

    if (!activeSubscription) {
      await activatePlan();
    } else {
      await upgradePlan();
    }

    if (onToggleSubscriptionPolling) onToggleSubscriptionPolling();
  }, [
    activatePlan,
    activeSubscription,
    onToggleSubscriptionPolling,
    selectedProduct,
    upgradePlan,
  ]);

  useEffect(() => {
    if (!totalSeats) return;

    setSeatCount(totalSeats);
    onSelectPlan(activeProduct);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubscription, tenantType, totalSeats, activeProduct]);

  useEffect(() => {
    if (chosenProduct) {
      onSelectPlan(chosenProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenProduct]);

  const updateAddonState = (addOn: Product, status: boolean) => {
    if (status) {
      setAddonState((prev) => ({ ...prev, [addOn.identifier as string]: 1 }));
    }
    else {
      // Remove the add-on from the state
      setAddonState((prev) => {
        const { [addOn.identifier as string]: val, ...rest } = prev;
        return rest;
      });
    }
  }

  const updateAddonQuantity = (addOn: Product, quantity: number) => {
    if (!addOn.quantity_required || !quantity) return;

    setAddonState((prev) => ({ ...prev, [addOn.identifier as string]: quantity }));
  }

  const renderPlanData = () => {
    let seatDifference = 0;

    if (selectedProduct && activeProduct) {
      // Assuming the first and only product is out sub.
      const currentSeatCount = activeSubscription?.products?.[0]?.quantity ?? 1;
      if (isNumber(selectedProduct.max_quantity) && isNumber(currentSeatCount)) {
        seatDifference = selectedProduct.max_quantity - currentSeatCount;
      }
    }

    return (
      <>
        <Grid container spacing={{ xs: 0.5, md: 2 }}>
          <Grid xs={12} md={4} sx={{ color: 'text.secondary', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            {t('billing.payment.base_price')}
          </Grid>
          <Grid xs={12} md={8} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
            {selectedProduct && selectedPrice ? fCurrency(selectedPrice) : t('common.free')}
          </Grid>
        </Grid>

        {
          (!isFreeProduct && selectedProduct?.quantity_required) && (
            <Grid container spacing={{ xs: 0.5, md: 2 }}>
              <Grid xs={12} md={4} sx={{ color: 'text.secondary', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                {t('billing.payment.seat_price')}
              </Grid>
              <Grid xs={12} md={8} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                <span>{fCurrency(currentSeatPrice)}</span>
              </Grid>
            </Grid>
          )}

        {
          selectedProduct?.quantity_required && (
            <Grid container spacing={{ xs: 0.5, md: 2 }}>
              <Grid xs={12} md={4} sx={{ color: 'text.secondary', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                {t(`billing.product.max_seats_${tenantType}`)}
              </Grid>
              <Grid xs={12} md={8} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                <>
                  <span>{selectedProduct?.max_quantity ?? t('billing.product.unlimited_seats')}</span>
                  {isNumber(seatDifference) && seatDifference !== 0 && (
                    <span className={`${seatDifference < 0 ? 'text-red-600' : 'text-green-500'} ml-2`}>
                      {`(${seatDifference > 0 ? '+' : ''}${seatDifference.toString()})`}
                    </span>
                  )}
                </>
              </Grid>
            </Grid>
          )
        }

        {
          (availableAddOns.length > 0 && addonState) && (
            <Grid container alignItems="center" spacing={{ xs: 0.5, md: 2 }}>
              <Grid xs={12} md={4} sx={{ color: 'text.secondary', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                {t('billing.subscription.add_ons')}
              </Grid>
              <Grid xs={12} md={8} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                {
                  availableAddOns.map((addOn) => (
                    <Stack key={addOn.id} spacing={1} direction="row" justifyContent="start" alignItems="center">
                      <Stack direction="row" justifyContent="start" alignItems="center">
                        <Box>
                          {addOn.name}
                        </Box>
                        <Switch checked={addonState[addOn.identifier as string] as boolean ?? false} onChange={(e) => updateAddonState(addOn, e.target.checked)} />
                      </Stack>
                      {
                        (addOn.quantity_required && addonState[addOn.identifier as string]) && (
                          <NumberInputBasic
                            value={addonState[addOn.identifier as string] as number}
                            onChange={(e, val) => updateAddonQuantity(addOn, val as number)}
                            min={1}
                          />
                        )
                      }
                    </Stack>
                  ))
                }
              </Grid>
            </Grid>
          )
        }

        <Grid container spacing={{ xs: 0.5, md: 2 }}>
          <Grid xs={12} md={4} sx={{ color: 'text.secondary', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            {t('billing.payment.total_price')}
          </Grid>
          <Grid xs={12} md={8} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
            {selectedProduct && totalPrice
              ? `${fCurrency(totalPrice)} /${payPeriod === PayPeriod.Monthly
                ? t('common.monthly_short')
                : t('common.yearly_short')
              }`
              : t('common.free')}
          </Grid>
        </Grid>
      </>
    );
  };

  const isActionDisabled = useMemo(() => {
    if (!isDataChanged) {
      return true;
    }

    if (selectedProduct?.subscription_required && selectedCard && authData?.organization.payment_collection_type === PaymentCollectionType.AUTOMATIC_COLLECTION) {
      return false;
    }

    if (selectedProduct?.subscription_required && authData?.organization.payment_collection_type === PaymentCollectionType.INVOICED) {
      return false;
    }

    return true;
  }, [selectedProduct, selectedCard, isDataChanged, authData]);

  return (
    <>
      <PaymentNewCardDialog open={newCard.value} onClose={newCard.onFalse} />
      <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: 3, pr: 1.5 }}
        >
          <Typography variant="h6">{t('billing.subscription.upgrade_subscription')}</Typography>
        </Stack>

        <Stack sx={{ px: 3 }} flexDirection="column">
          <Stack spacing={3} sx={{ p: 3, pt: 0, typography: 'body2' }} key="view-product-details">
            <Typography variant="subtitle1">{t('billing.product.title')}</Typography>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <Grid container spacing={{ xs: 0.5, md: 2 }}>
              <Grid xs={12} md={4} sx={{ color: 'text.secondary', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                {t('billing.subscription.company_size')}
              </Grid>
              <Grid xs={12} md={8} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                <Select
                  value={selectedProduct}
                  onChange={(event) => onSelectPlan(event.target.value as Product)}
                  renderValue={(v) => v.name}
                  fullWidth
                >
                  {tenantProducts?.map((product) => (
                    <MenuItem key={product.id} value={product as any}>
                      {product.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            <Grid container spacing={{ xs: 0.5, md: 2 }}>
              <Grid xs={12} md={4} sx={{ color: 'text.secondary', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                {t('billing.payment.pay_period')}
              </Grid>
              <Grid xs={12} md={8} flexDirection="row">
                <Typography
                  display="inline"
                  sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
                >
                  {t('common.monthly')}
                </Typography>
                <Switch checked={payPeriod === PayPeriod.Yearly} onChange={onChangePayPeriod} />
                <Typography
                  display="inline"
                  sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
                >
                  {t('common.yearly')}
                </Typography>
              </Grid>
            </Grid>

            {
              selectedProduct?.quantity_required &&
              <Grid container spacing={{ xs: 0.5, md: 2 }}>
                <Grid xs={12} md={4} sx={{ color: 'text.secondary', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  {t('billing.payment.seats')}
                </Grid>
                <Grid xs={12} md={4} sx={{ typography: 'subtitle2' }}>
                  <NumberInputBasic
                    value={seatCount}
                    onChange={(_e, val) => onChangeSeatCount(val)}
                    min={1}
                    max={99}
                    className="w-100"
                  />
                </Grid>
              </Grid>
            }

            {renderPlanData()}

            {
              authData?.organization.payment_collection_type === PaymentCollectionType.AUTOMATIC_COLLECTION &&
              <Grid container spacing={{ xs: 0.5, md: 2 }}>
                <Grid xs={12} md={4} sx={{ color: 'text.secondary', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  {t('billing.payment.payment_method')}
                </Grid>
                <Grid xs={12} md={8}>
                  <Button
                    onClick={handleClickCards}
                    endIcon={<Iconify width={16} icon="eva:arrow-ios-downward-fill" />}
                    sx={{ typography: 'subtitle2', p: 0, borderRadius: 0 }}
                  >
                    {selectedCard
                      ? `**** **** **** ${selectedCard.metadata.last_4}`
                      : `${t('common.add')} ${t('billing.payment.payment_method')}`}
                  </Button>
                  <Popover
                    id={id}
                    open={openCardPopover}
                    anchorEl={cardAnchorEl}
                    onClose={handleCloseCards}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <>
                      {cardData?.results &&
                        cardData.results.map((card) => (
                          <PaymentCardItem
                            key={card.id}
                            sx={{ minWidth: 350 }}
                            card={card}
                            onClick={onSelectCard as any}
                            hideControls
                          />
                        ))}
                      <Button variant="text" onClick={newCard.onTrue}>
                        {`${t('billing.payment.new_card')} +`}
                      </Button>
                    </>
                  </Popover>
                </Grid>
              </Grid>
            }

            {
              selectedProduct?.subscription_required &&
              <Grid container spacing={{ xs: 0.5, md: 2 }}>
                <Grid xs={12} md={4} sx={{ color: 'text.secondary', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  {t('billing.payment.discount_code')}
                </Grid>
                <Grid xs={12} md={8} sx={{ typography: 'subtitle2' }}>
                  <TextField sx={{ width: '100%' }} onChange={(e) => setDiscountCode(e.target.value)} value={discountCode} />
                </Grid>
              </Grid>
            }

            {
              apiError &&
              <Grid container spacing={{ xs: 0.5, md: 2 }}>
                <Grid xs={12}>
                  <Alert severity="error">
                    {apiError}
                  </Alert>
                </Grid>
              </Grid>
            }

            <Grid container spacing={{ xs: 0.5, md: 2 }}>
              <Grid xs={12} md={12} className="pt-8">
                <LoadingButton
                  loading={isActivating || isUpgrading}
                  fullWidth
                  variant="contained"
                  disabled={isActionDisabled}
                  onClick={onSubscribe}
                >
                  {isError ? t('common.subscribe') : t('common.upgrade')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
}
