import { isString } from 'lodash';
import { Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { m, AnimatePresence } from 'framer-motion';

import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/system/Unstable_Grid/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import {
  Box,
  List,
  Stack,
  Button,
  ListItem,
  useTheme,
  TextField,
  Typography,
  ListItemText,
  CircularProgress,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { fCurrency } from 'src/utils/format-number';

import i18n from 'src/locales/i18n';
import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { DocumentType } from 'src/services/documents/documents.types';
import { ComplianceRequirementType } from 'src/services/shared/shared.types';
import { useGetMyProfileQuery } from 'src/services/candidates/candidates.service';
import { CandidateProfileDocument } from 'src/services/candidates/candidates.types';
import { hideCandidateOfferModal } from 'src/store/slices/applications/applicationsSlice';
import { Placement, OfferAction, OfferStatus } from 'src/services/applications/applications.types';
import {
  useGetOfferByIdQuery,
  useActionOfferMutation,
  useAddPlacementToProfileMutation,
} from 'src/services/applications/applications.service';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';

import { ProfileDocumentSelector } from 'src/sections/profile/documents/profile-document-selector';

import { TenantType } from 'src/types/enums';
import { BusinessErrorCodes } from 'src/types/business-errors';

type Props = {
  open: boolean;
};

const generateActionOfferError = (e: any, action: OfferAction): string => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.InvalidOfferStatus) {
      return i18n.t('applications.api.action_offer.invalid_state');
    }

    if (e?.data.error_code === BusinessErrorCodes.PlacementExists) {
      return i18n.t('applications.api.action_offer.placement_exists');
    }
  }

  if (action === OfferAction.Accept) {
    return i18n.t('applications.api.action_offer.accept_default_error');
  }
  return i18n.t('applications.api.action_offer.decline_default_error');
};

export default function CandidateOfferModal({ open }: Props) {
  const { t } = useTranslate();

  const theme = useTheme();

  const tenantType = useOrgTenant();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();

  const selectedOfferId = useAppSelector((state) => state.applications.selectedOfferId);

  const confirmAccept = useBoolean();

  const confirmDecline = useBoolean();

  const [placement, setPlacement] = useState<Placement | null>(null);

  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [selectedDocuments, setSelectedDocuments] = useState<
    { profile_document_id: string; type: DocumentType }[]
  >([]);

  const [currentView, setCurrentView] = useState<'detail' | 'documents' | 'add_to_profile'>('detail');

  const { currentData: offer, isLoading } = useGetOfferByIdQuery(
    { offerId: selectedOfferId as string },
    { skip: !selectedOfferId }
  );

  const onClose = () => {
    dispatch(hideCandidateOfferModal());
  };

  const [actionOffer, { isLoading: isSubmitting }] = useActionOfferMutation();

  const [addToProfile, { isLoading: isAddingToProfile }] = useAddPlacementToProfileMutation();

  const { currentData: myProfile } = useGetMyProfileQuery(undefined, {
    skip: tenantType !== TenantType.Candidate
  });

  const requiredDocuments = useMemo(() => {
    if (!offer || !Array.isArray(offer.compliance_requirements)) return [];

    return offer.compliance_requirements.filter((req) => req.requirement_type === ComplianceRequirementType.DOCUMENT).map((req) => ({
      type: req.item_type as DocumentType,
      custom_type_name: req.custom_type_name as string
    }));
  }, [offer]);

  const handleAccept = () => {
    if (requiredDocuments.length > 0 && currentView === 'detail') {
      setCurrentView('documents');
    }
    else {
      confirmAccept.onTrue();
    }
  }

  const onActionOffer = async (action: OfferAction) => {

    // check if all required documents are uploaded
    // check if the correct types of documents are uploaded
    if (action === OfferAction.Accept && requiredDocuments.length > 0) {
      const missingDocuments = requiredDocuments.filter((doc) => !selectedDocuments.some((selectedDoc) => selectedDoc.type === doc.type));
      if (requiredDocuments.length > 0 && (selectedDocuments.length !== requiredDocuments.length || missingDocuments.length > 0)) {
        setErrorMsg(t('applications.candidate_offer_modal.documents.errors.required'));
        return;
      }
    }

    try {

      const result = await actionOffer({
        offerId: selectedOfferId as string,
        action,
        documents: selectedDocuments,
      }).unwrap();

      setPlacement(result);

      if (action === OfferAction.Accept) {
        enqueueSnackbar(
          t('applications.api.action_offer.accept_success', {
            role: offer?.position_title,
            company: offer?.employer_name,
          }),
          {
            variant: 'success',
          }
        );

        confirmAccept.onFalse();
        setCurrentView('add_to_profile');
      } else {
        enqueueSnackbar(
          t('applications.api.action_offer.reject_success', {
            role: offer?.position_title,
            company: offer?.employer_name,
          }),
          {
            variant: 'success',
          }
        );

        onClose();
      }
    } catch (e: any) {
      enqueueSnackbar(generateActionOfferError(e, action), {
        variant: 'error',
      });
    }
  };

  const onAddToProfile = async () => {
    if (!placement) return;

    try {
      await addToProfile({ placementId: placement.id }).unwrap();

      enqueueSnackbar(t('applications.api.add_to_profile.success'), {
        variant: 'success',
      });

      onClose();
    } catch (e: any) {
      enqueueSnackbar(t('applications.api.add_to_profile.error'), {
        variant: 'error',
      });
    }
  };

  const onSelectDocument = (type: DocumentType, document: CandidateProfileDocument | null) => {

    if (!document) {
      setSelectedDocuments((prev) => prev.filter((doc) => doc.type !== type));
      return;
    }

    setSelectedDocuments((prev) => {
      const existingIndex = prev.findIndex((doc) => doc.type === type);
      if (existingIndex !== -1) {
        return prev.map((doc) => {
          if (doc.type === type) {
            return { profile_document_id: document.id, type };
          }
          return doc;
        });
      }

      return [...prev, { profile_document_id: document.id, type }];
    });
  }

  const offerValid = useMemo(() => offer?.status === OfferStatus.SENT, [offer?.status]);

  const renderRequestHead = (
    <Box textAlign="center">
      <Iconify
        icon="solar:verified-check-bold"
        sx={{
          height: 96,
          width: 96,
          dropShadow: theme.shadows[3],
          color: theme.palette.primary.main,
        }}
      />

      <Stack spacing={1} sx={{ mt: 1, mb: 5 }}>
        <Typography variant="h3">
          {offerValid
            ? t('applications.candidate_offer_modal.header.title')
            : t('applications.candidate_offer_modal.header.actioned_title')}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <Trans>
            {offerValid
              ? t('applications.candidate_offer_modal.header.subheader', {
                role: offer?.position_title,
                company: offer?.employer_name,
              })
              : t('applications.candidate_offer_modal.header.actioned_subheader')}
          </Trans>
        </Typography>
      </Stack>
    </Box>
  );

  const renderAcceptedHead = (
    <Box textAlign="center">
      <Iconify
        icon="solar:verified-check-bold"
        sx={{
          height: 96,
          width: 96,
          dropShadow: theme.shadows[3],
          color: theme.palette.success.main,
        }}
      />

      <Stack spacing={1} sx={{ mt: 1, mb: 5 }}>
        <Typography variant="h3">
          {t('applications.candidate_offer_modal.header.accepted_title')}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {t('applications.candidate_offer_modal.header.accepted_subheader', {
            role: offer?.position_title,
            company: offer?.employer_name,
          })}
        </Typography>
      </Stack>
    </Box>
  );

  const renderRequest = (
    <Grid container spacing={2}>
      <Grid xs={6}>
        <TextField
          fullWidth
          name="email"
          label={t('external.verification.form.company')}
          value={offer?.employer_name}
          InputLabelProps={{ shrink: true }}
          disabled
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.text.primary,
            },
          }}
        />
      </Grid>

      <Grid xs={6}>
        <TextField
          fullWidth
          value={offer?.position_title}
          name="email"
          label={t('external.verification.form.position')}
          InputLabelProps={{ shrink: true }}
          disabled
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.text.primary,
            },
          }}
        />
      </Grid>

      <Grid xs={6}>
        <MobileDatePicker
          value={new Date(`${offer?.start_date}`)}
          name="start_date"
          label={t('external.verification.form.start')}
          disabled
          sx={{
            width: '100%',
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.text.primary,
            },
          }}
        />
      </Grid>

      <Grid xs={6}>
        {!offer?.end_date ? (
          <TextField
            fullWidth
            value={t('enums.employment_type.permanent')}
            name="email"
            label={t('external.verification.form.end')}
            InputLabelProps={{ shrink: true }}
            disabled
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.text.primary,
              },
            }}
          />
        ) : (
          <MobileDatePicker
            value={new Date(`${offer?.end_date}`)}
            name="start_date"
            label={t('external.verification.form.end')}
            disabled
            sx={{
              width: '100%',
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.text.primary,
              },
            }}
          />
        )}
      </Grid>

      <Grid xs={12}>
        <TextField
          fullWidth
          value={
            offer?.pay_rate
              ? `${fCurrency(offer?.pay_rate)} ${t(`enums.rate_period_per.${offer?.rate_period}`)}`
              : t('common.unknown')
          }
          name="email"
          label={t('external.verification.form.salary')}
          InputLabelProps={{ shrink: true }}
          disabled
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.text.primary,
            },
          }}
        />
      </Grid>

      <Grid xs={12} flexDirection="row" spacing={2}>
        <LoadingButton
          loading={isSubmitting}
          fullWidth
          variant="outlined"
          onClick={() => onActionOffer(OfferAction.Decline)}
          disabled={!offerValid}
        >
          {t('common.decline')}
        </LoadingButton>

        <LoadingButton
          onClick={handleAccept}
          loading={isSubmitting}
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          disabled={!offerValid}
        >
          {t('common.accept')}
        </LoadingButton>
      </Grid>
    </Grid>
  );

  const renderAddToProfile = (
    <Stack spacing={1} sx={{ mt: 2 }} textAlign="center">
      <Stack spacing={2}>
        <LoadingButton
          loading={isAddingToProfile}
          variant="contained"
          color="primary"
          fullWidth
          onClick={onAddToProfile}
        >
          {t('applications.candidate_offer_modal.add_to_profile.button')}
        </LoadingButton>

        <Button variant="outlined" color="primary" fullWidth onClick={onClose}>
          {t('common.cancel')}
        </Button>
      </Stack>
    </Stack>
  );

  const renderDocumentRequests = (
    <List>
      {requiredDocuments.map((item, index) => (
        <ListItem
          sx={{ my: .5 }}
          key={index}
          secondaryAction={
            <ProfileDocumentSelector
              type={item.type as DocumentType}
              onSelect={onSelectDocument}
              profileId={myProfile?.id as string}
            />
          }
        >
          <ListItemText
            sx={{ my: 1 }}
            primary={t(`enums.document_type.${item.type}`)}
            secondary={item.type === DocumentType.OTHER && item.custom_type_name}
            secondaryTypographyProps={{ variant: 'caption' }}
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>
        {t(`applications.candidate_offer_modal.title`)}
      </DialogTitle>

      <DialogContent>
        {
          currentView === 'detail' && (
            <AnimatePresence mode="popLayout" initial={false}>
              {isLoading ? (
                <m.div key="loader">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 200,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </m.div>
              ) : (
                <m.div key="header">{renderRequestHead}</m.div>
              )}

              {!isLoading && <m.div key="request">{renderRequest}</m.div>}
            </AnimatePresence>
          )
        }

        {
          currentView === 'documents' && (
            <Box>
              <Stack direction="column" alignItems="start" sx={{ mb: 2 }}>
                <Typography variant="h6">
                  {t('applications.candidate_offer_modal.documents.title')}
                </Typography>
                <Typography variant="caption">
                  {t('applications.candidate_offer_modal.documents.subheader')}
                </Typography>
              </Stack>

              {renderDocumentRequests}

              {errorMsg && (
                <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                  {errorMsg}
                </Typography>
              )}

              <LoadingButton
                onClick={() => onActionOffer(OfferAction.Accept)}
                loading={isSubmitting}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                disabled={!offerValid}
              >
                {t('common.submit')}
              </LoadingButton>
            </Box>
          )
        }

        {currentView === "add_to_profile" && (
          <AnimatePresence mode="popLayout" initial={false}>
            {isLoading ? (
              <m.div key="loader">
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 200,
                  }}
                >
                  <CircularProgress />
                </Box>
              </m.div>
            ) : (
              <m.div key="header-accepted">{renderAcceptedHead}</m.div>
            )}

            <m.div key="profile">{renderAddToProfile}</m.div>
          </AnimatePresence>
        )}

      </DialogContent>

      <DialogActions>
        {
          currentView === 'documents' && (
            <Button onClick={() => setCurrentView('detail')}>{t('common.back')}</Button>
          )
        }

        {
          ['documents', 'detail'].includes(currentView) && (
            <Button onClick={onClose}>{t('common.cancel')}</Button>
          )
        }

      </DialogActions>

      <ConfirmDialog
        open={confirmAccept.value}
        title={t('applications.candidate_offer_modal.confirm_accept.title')}
        content={t('applications.candidate_offer_modal.confirm_accept.content')}
        action={
          <LoadingButton
            onClick={() => onActionOffer(OfferAction.Accept)}
            loading={isSubmitting}
            variant="contained"
            color="primary"
            disabled={!offerValid}
          >
            {t('common.accept')}
          </LoadingButton>
        }
        onClose={confirmAccept.onFalse}
      />

      <ConfirmDialog
        open={confirmDecline.value}
        title={t('applications.candidate_offer_modal.confirm_accept.title')}
        content={t('applications.candidate_offer_modal.confirm_accept.content')}
        action={
          <LoadingButton
            loading={isSubmitting}
            fullWidth
            variant="outlined"
            color="error"
            onClick={() => onActionOffer(OfferAction.Decline)}
            disabled={!offerValid}
          >
            {t('common.decline')}
          </LoadingButton>
        }
        onClose={confirmDecline.onFalse}
      />
    </Dialog>
  );
}
