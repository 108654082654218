import { FileResponse } from "src/services/utils/utils.types";

import { TenantType } from "src/types/enums";

export interface TermsOfServiceResponse {
  id: string;
  content: string;
}

export enum BackgroundTaskType {
  FINALIZE_APPLY_INTENT = 'finalize_apply_intent'
}

export enum BackgroundTaskStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed'
}

export interface IBackgroundTask {
  id: string;
  type: BackgroundTaskType;
  status: BackgroundTaskStatus;
  created_at: Date;
}

export interface SystemDocument {
  id: string;
  name: string;
  file: FileResponse;
  target_types: TenantType[];
  created_at: Date;
}

export interface CreateSystemDocumentDto {
  name: string;

  file_id: string;

  tenant_types: TenantType[];
}

export interface TypeDefinitionsResponse {
  [key: string]: string[];
}
export type Language = {
  id: string;
  name: string;
  code: string;
  native_name: string;
};

export enum DataType {
  STRING = 'string',
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  JSON = 'json'
}

export enum SystemAttributeKey {
  MAINTENANCE_MODE = 'MAINTENANCE_MODE',
  MAINTENANCE_MESSAGE = 'MAINTENANCE_MESSAGE',
}

export type SystemAttribute = {
  key: SystemAttributeKey;
  value: any;
  data_type: DataType;
};

export enum TermsType {
  TERMS_OF_USE = 'terms_of_use',
  TERMS_OF_ENGAGEMENT = 'terms_of_engagement'
}

export type UsageTerm = {
  id: string;
  type: string;
  content: string;
  region: string;
  target_type: TenantType;
  document_type: TermsType;
  status: TermStatus;
};

export enum TermStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}