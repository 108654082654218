export interface Notification {
  id: string;
  status: NotificationStatus;
  type: NotificationType;
  category: NotificationCategory;
  metadata: {
    subject?: string;
    template?: string;
    variables?: {
      preHeader: string;
      url?: string;
      label?: string;
      initiator_id?: string;
      initiator_name?: string;
      request_id?: string;
      target_name?: string;
      target_id?: string;
      company_name?: string;
      job_title?: string;
      job_id?: string;
      link_request_id?: string;
      application_id?: string;
      target_stage?: string;
      offer_id?: string;
      interview_id?: string;
      candidate_id: string;
    };
    body?: string;
    title?: string;
    eventType: string;
  };
}

export type SocketNotification = {
  id: string;
  status: NotificationStatus;
  type: NotificationType;
  category: NotificationCategory;
  body: string;
  variables?: {
    url?: string;
    label?: string;
    initiator_id?: string;
    initiator_name?: string;
    request_id?: string;
    target_name?: string;
    target_id?: string;
    company_name?: string;
    application_id?: string;
    thread_id?: string;
    job_id?: string;
  };
};

export enum NotificationChannel {
  EMAIL = 'email',
  SMS = 'sms',
  IN_APP = 'in_app',
}

export enum NotificationStatus {
  UNSENT = 'unsent',
  SENT = 'sent',
  DELIVERED = 'delivered',
  DELIVERY_FAILED = 'delivery_failed',
  READ = 'read',
  BLOCKED = 'blocked',
}

export enum NotificationCategory {
  SYSTEM = 'SYSTEM',
  BILLING = 'BILLING',
  ACCOUNT = 'ACCOUNT',
  CONNECTION = 'CONNECTION',
  ORGANIZATION = 'ORGANIZATION',
  JOB = 'JOB',
  JOB_INVITE = 'JOB_INVITE',
  JOB_APPLICATION = 'JOB_APPLICATION',
  INTERVIEW = 'INTERVIEW',
  CANDIDATE = 'CANDIDATE',
}

export enum NotificationType {
  // transactional
  ACCOUNT_VERIFICATION = 'ACCOUNT_VERIFICATION',
  PASSWORD_RESET_REQUESTED = 'PASSWORD_RESET_REQUESTED',
  ACCOUNT_PASSWORD_CHANGED = 'ACCOUNT_PASSWORD_CHANGED',

  SUBSCRIPTION_ACTIVATED = 'SUBSCRIPTION_ACTIVATED',
  SUBSCRIPTION_CANCELLED = 'SUBSCRIPTION_CANCELLED',
  SUBSCRIPTION_RENEWED = 'SUBSCRIPTION_RENEWED',
  SUBSCRIPTION_PAYMENT_FAILED = 'SUBSCRIPTION_PAYMENT_FAILED',
  SUBSCRIPTION_CANCEL_PENDING = 'SUBSCRIPTION_CANCEL_PENDING',

  // account related
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  ACCOUNT_SUSPENDED = 'ACCOUNT_SUSPENDED',
  ACCOUNT_REGISTRATION_ATTEMPT = 'ACCOUNT_REGISTRATION_ATTEMPT',

  // connection related
  CONNECTION_DISCONNECT = 'CONNECTION_DISCONNECT',
  REQUEST_PENDING_APPROVAL = 'REQUEST_PENDING_APPROVAL',
  INITIATE_CONNECTION_REQUEST = 'INITIATE_CONNECTION_REQUEST',
  REQUESTS_AVAILABLE = 'REQUESTS_AVAILABLE',
  CONNECTION_REQUEST_ACCEPTED = 'CONNECTION_REQUEST_ACCEPTED',
  CONNECTION_REQUEST_REJECTED = 'CONNECTION_REQUEST_REJECTED',

  // company related
  COMPANY_INVITE_INTITATED = 'COMPANY_INVITE_INTITATED',
  COMPANY_INVITE_ACCEPTED = 'COMPANY_INVITE_ACCEPTED',

  // job invites related
  JOB_INVITE_INITIATED = 'JOB_INVITE_INITIATED',
  JOB_INVITE_ACCEPTED = 'JOB_INVITE_ACCEPTED',
  JOB_INVITE_REJECTED = 'JOB_INVITE_REJECTED',

  // Job Change Requests
  JOB_CHANGES_REQUESTED = 'JOB_CHANGES_REQUESTED',

  // job application related
  APPLICATION_STAGE_CHANGED = 'APPLICATION_STAGE_CHANGED',

  APPLICATION_PLACEMENT = 'APPLICATION_PLACEMENT',
  APPLICATION_REJECTED = 'APPLICATION_REJECTED',
  APPLICATION_SHORTLISTED = 'APPLICATION_SHORTLISTED',

  APPLICATION_OFFER_CREATED = 'APPLICATION_OFFER_CREATED',
  APPLICATION_OFFER_EXTENDED = 'APPLICATION_OFFER_EXTENDED',
  APPLICATION_OFFER_RESCINDED_ALERT = 'APPLICATION_OFFER_RESCINDED_ALERT',
  APPLICATION_OFFER_RESCINDED = 'APPLICATION_OFFER_RESCINDED',
  APPLICATION_OFFER_ACCEPTED = 'APPLICATION_OFFER_ACCEPTED',
  APPLICATION_OFFER_ACCEPTED_ALERT = 'APPLICATION_OFFER_ACCEPTED_ALERT',
  APPLICATION_OFFER_REJECTED = 'APPLICATION_OFFER_REJECTED',
  APPLICATION_OFFER_CHANGES_REQUESTED = 'APPLICATION_OFFER_CHANGES_REQUESTED',

  APPLICATION_RESOURCE_REQUEST_INTITATED = 'APPLICATION_RESOURCE_REQUEST_INTITATED',
  APPLICATION_RESOURCE_REQUEST_ACCEPTED = 'APPLICATION_RESOURCE_REQUEST_ACCEPTED',
  APPLICATION_RESOURCE_REQUEST_REJECTED = 'APPLICATION_RESOURCE_REQUEST_REJECTED',

  APPLICATION_STAGE_CHANGE_REQUEST_INITIATED = 'APPLICATION_STAGE_CHANGE_REQUEST_INITIATED',
  APPLICATION_STAGE_CHANGE_REQUEST_ACCEPTED = 'APPLICATION_STAGE_CHANGE_REQUEST_ACCEPTED',
  APPLICATION_STAGE_CHANGE_REQUEST_REJECTED = 'APPLICATION_STAGE_CHANGE_REQUEST_REJECTED',

  // interview related
  INTERVIEW_SCHEDULED = 'INTERVIEW_SCHEDULED',
  INTERVIEW_CANCELLED = 'INTERVIEW_CANCELLED',
  INTERVIEW_AVAILABILITY_CONFIMRED = 'INTERVIEW_AVAILABILITY_CONFIMRED',
  INTERVIEW_IN_REVIEW = 'INTERVIEW_IN_REVIEW',
  INTERVIEW_REMINDER = 'INTERVIEW_REMINDER',

  // jobs
  JOB_CLOSED = 'JOB_CLOSED',
  JOB_REOPENED = 'JOB_REOPENED',
  JOB_FILLED = 'JOB_FILLED',

  JOB_VERIFICATION_REQUEST_INITIATED = 'JOB_VERIFICATION_REQUEST_INITIATED',
  JOB_VERIFICATION_REQUEST_ACCEPTED = 'JOB_VERIFICATION_REQUEST_ACCEPTED',
  JOB_VERIFICATION_REQUEST_REJECTED = 'JOB_VERIFICATION_REQUEST_REJECTED',
  JOB_VERIFICATION_REQUEST_APPROVED = 'JOB_VERIFICATION_REQUEST_APPROVED',

  JOB_STAKEHOLDER_ADDED = 'JOB_STAKEHOLDER_ADDED',
  JOB_STAKEHOLDER_REMOVED = 'JOB_STAKEHOLDER_REMOVED',

  // candidate
  CANDIDATE_AVAILABILITY_UPDATED = 'CANDIDATE_AVAILABILITY_UPDATED',
  CANDIDATE_UNAVAILABLE = 'CANDIDATE_UNAVAILABLE',
  CANDIDATE_INVITE_ACCEPTED = 'CANDIDATE_INVITE_ACCEPTED',
  CANDIDATE_INVITE_INITIATED = 'CANDIDATE_INVITE_INITIATED',

  CANDIDATE_JOB_OFFER_STAGE = 'CANDIDATE_JOB_OFFER_STAGE',

  // JOBS
  // Target Receives
  JOB_LINK_REQUEST_INITIATED = 'JOB_LINK_REQUEST_INITIATED',
  JOB_LINK_REQUEST_ACCEPTED = 'JOB_LINK_REQUEST_ACCEPTED',
  JOB_LINK_REQUEST_REJECTED = 'JOB_LINK_REQUEST_REJECTED',

  // Sender Receives
  ROLE_CLOSED = 'ROLE_CLOSED',
  ROLE_ON_HOLD = 'ROLE_ON_HOLD',
  ROLE_FILLED = 'ROLE_FILLED',
  JOB_REQUIRES_APPROVAL = 'JOB_REQUIRES_APPROVAL',
  JOB_CLIENT_VERIFIED = 'JOB_CLIENT_VERIFIED',
  JOB_RECRUITER_ASSIGNED = 'JOB_RECRUITER_ASSIGNED',
  MASTER_JOB_UPDATED = 'MASTER_JOB_UPDATED',

  INTEGRATION_EXPIRED = 'INTEGRATION_EXPIRED',

  // System
  THREAD_COMMENT_CREATED = 'THREAD_COMMENT_CREATED',
}

export interface MarkAsReadPayload {
  notifications: string[];
}

export type NotificationSchema = {
  type: string;
  category: string;
  channels: string[];
};
