import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { BackgroundTaskType } from 'src/services/system/system.types';

export interface IAppState {
  maintenanceMode: boolean;
  maintenanceMessage?: string;
  documentViewer: {
    open: boolean;
    fileUrl?: string;
  };
  background_task_state: {
    task_type?: BackgroundTaskType;
    total_count: number;
    pending_count: number;
    completed_count: number;
    failed_count: number;
  };
}

const initialState: IAppState = {
  maintenanceMode: false,
  maintenanceMessage: undefined,
  documentViewer: {
    open: false,
    fileUrl: undefined,
  },
  background_task_state: {
    task_type: undefined,
    total_count: 0,
    pending_count: 0,
    completed_count: 0,
    failed_count: 0
  }
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMaintenanceMode: (state, action: PayloadAction<{ mode_state: boolean, message?: string }>) => {
      state.maintenanceMode = action.payload.mode_state;
      state.maintenanceMessage = action.payload.message;
    },
    openDocumentViewer: (state, action: PayloadAction<{ fileUrl: string }>) => {
      state.documentViewer.open = true;
      state.documentViewer.fileUrl = action.payload.fileUrl;
    },
    closeDocumentViewer: (state) => {
      state.documentViewer.open = false;
      state.documentViewer.fileUrl = undefined;
    },
    updateBackgroundTaskState: (state, action: PayloadAction<{ task_type: BackgroundTaskType, total_count: number, pending_count: number, completed_count: number, failed_count: number }>) => {
      state.background_task_state = action.payload;
    },
    resetBackgroundTaskState: (state) => {
      state.background_task_state = {
        task_type: undefined,
        total_count: 0,
        pending_count: 0,
        completed_count: 0,
        failed_count: 0,
      };
    },
  },
});

export const {
  setMaintenanceMode,
  openDocumentViewer,
  closeDocumentViewer,
  updateBackgroundTaskState,
  resetBackgroundTaskState,
} = appSlice.actions;
export const appReducer = appSlice.reducer;
