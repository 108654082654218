import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useMemo, useCallback } from 'react';

import { Box, Stack, useMediaQuery } from '@mui/system';
import {
  Card,
  TableRow,
  useTheme,
  TableCell,
  CardHeader,
  Typography,
  CardContent,
  CardActionArea
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { ApplicationStage } from 'src/services/applications/applications.types';
import { ICandidateApplication } from 'src/services/candidates/candidates.types';

import Iconify from 'src/components/iconify';
import NestedMenu from 'src/components/menu/nested-menu';
import DateDisplay from 'src/components/date/date-display';
import TruncatedTextField from 'src/components/truncate-text/truncated-text-field';
import VerificationStatusLabel from 'src/components/verification-status-label/verification-status-label';

import { ApplicationStatusLabel } from 'src/sections/jobs/applications/applications-status-label';

import { TenantType } from 'src/types/enums';

type JobTableRowProps = {
  row: ICandidateApplication;
  disableControls?: boolean;
};

export default function JobTableRow({ row, disableControls = false }: JobTableRowProps) {
  const { t } = useTranslate();

  const tenant = useOrgTenant();

  const router = useRouter();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const viewDetail = useCallback(() => {
    router.push(`${paths.dashboard.jobs.root}/${row.id}`);
  }, [router, row.id]);

  const menuItems = useMemo(() => {
    const items = [];

    items.push({
      label: t('common.view'),
      onClick: viewDetail,
      icon: <Iconify icon="solar:eye-bold" />,
    });

    return items;
  }, [t, viewDetail]);

  if (isMobile) {
    return (
      <Card sx={{ mx: 2, mb: 1 }}>
        <CardActionArea>
          <CardHeader
            title={
              <Link
                to={`${paths.dashboard.jobs.root}/${row.job.id}`}
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: 'none',
                }}
              >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Link
                    to={`${paths.dashboard.jobs.root}/${row.job.id}`}
                    style={{
                      color: theme.palette.text.primary,
                      textDecoration: 'none',
                      textWrap: 'nowrap',
                      textTransform: 'capitalize',
                    }}
                  >
                    <TruncatedTextField
                      text={row.job.title}
                      mode="characters"
                      limit={25}
                      showMoreEnabled={false}
                      variant="caption"
                      fontWeight="bold"
                      gutterBottom
                      style={{ textTransform: 'capitalize' }}
                    />
                  </Link>
                </Stack>
              </Link>
            }
            subheader={
              [TenantType.Recruiter].includes(tenant)
                ? row.job.client_company_name || row.job.client?.company_name
                : ''
            }
          />
          <CardContent sx={{ position: 'relative', pr: 6, cursor: 'pointer' }}>
            <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
              <ApplicationStatusLabel status={row.pre_finalization_stage as ApplicationStage} />
            </Stack>

            <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 50 }}>
              <NestedMenu
                menuItems={menuItems}
                trigger={{ type: 'icon', icon: <Iconify icon="eva:more-vertical-fill" /> }}
              />
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  return (
    <TableRow>
      <TableCell>
        <Stack direction="row" spacing={1} justifyContent="start" alignItems="center">
          <Link
            to={`${paths.dashboard.jobs.root}/${row.job.id}`}
            style={{
              color: theme.palette.text.primary,
              fontWeight: 'bold',
              textWrap: 'nowrap',
              textTransform: 'capitalize',
            }}
          >
            {row.job.title}
          </Link>
        </Stack>
      </TableCell>

      {[TenantType.Recruiter].includes(tenant) && (
        <TableCell>
          {row.job.client_profile?.id ? (
            <Stack direction="row" spacing={1}>
              <Link
                to={`${paths.dashboard.connections.clients}/${row.job.client_profile.id}`}
                className="text-gray-800"
              >
                {row.job.client?.company_name || row.job.client_company_name}
              </Link>
            </Stack>
          ) : (
            <Typography noWrap variant="subtitle2">
              {_.isEmpty(row.job.client_company_name) ? '-' : row.job.client_company_name}
            </Typography>
          )}
        </TableCell>
      )}

      <TableCell>
        {row.job.verification_status ? (
          <VerificationStatusLabel status={row.job.verification_status} />
        ) : (
          '-'
        )}
      </TableCell>

      <TableCell>
        <ApplicationStatusLabel status={row.pre_finalization_stage as ApplicationStage} />
      </TableCell>

      <TableCell>
        <DateDisplay date={row.updated_at} variant='body2' format='DD/MM/YYYY' />
      </TableCell>

      <TableCell align="right" sx={{ pr: 1 }}>
        <NestedMenu
          menuItems={menuItems}
          trigger={{ type: 'icon', icon: <Iconify icon="eva:more-horizontal-fill" /> }}
        />
      </TableCell>
    </TableRow>
  );
}
