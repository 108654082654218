import { t } from 'i18next';

import { Stack } from '@mui/system';
import { Box, Paper, Button, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { logOut } from 'src/auth/utils';
import { rootApi } from 'src/services/rootApi';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import maintenanceAnimation from 'src/assets/lottie/maintenance.json';

import Iconify from 'src/components/iconify';
import AnimationContainer from 'src/components/animation/container';

const MaintenanceModeOverlay = () => {

    const router = useRouter();
    const dispatch = useAppDispatch();

    const message = useAppSelector(
        (state) => state.app.maintenanceMessage
    );

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: maintenanceAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const handleLogout = () => {
        logOut();
        dispatch(rootApi.util.resetApiState());
        router.replace(paths.auth.login);
    }

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    maxWidth: 600,
                    padding: 4,
                    textAlign: 'center',
                    backgroundColor: 'background.paper',
                }}
            >
                <Box sx={{ width: '100%', margin: '0 auto' }}>
                    <AnimationContainer json={maintenanceAnimation} height={200} loop={false}/>
                </Box>

                <Stack sx={{ mt: 2, width: '100%' }} gap={0}>
                    <Typography variant="h6" component="h1">
                        {t('app.maintenance_overlay.title')}
                    </Typography>
                    <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                        {message || t('app.maintenance_overlay.subtitle')}
                    </Typography>
                </Stack>

                <Stack sx={{ mt: 5, justifyContent: 'center', alignItems: 'center' }} direction="row" spacing={2}>

                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<Iconify icon="mdi:reload" />}
                        onClick={() => window.location.reload()}
                    >
                        {t('app.maintenance_overlay.cta')}
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Iconify icon="mdi:logout" />}
                        onClick={handleLogout}
                    >
                        {t('navigationPopover.logout')}
                    </Button>
                </Stack>
            </Paper>
        </Box>
    );
};

export default MaintenanceModeOverlay;