
import { Stack } from '@mui/system';
import Button from '@mui/material/Button';
import {
  Box,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { useAppSelector } from 'src/store/store';

import Iconify from 'src/components/iconify';
import DocumentViewer from 'src/components/documents/document-viewer';

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function DocumentViewerModal({ open, onClose }: Props) {
  const { t } = useTranslate();

  const fileUrl = useAppSelector((state) => state.app.documentViewer.fileUrl);

  return (
    <Dialog fullScreen maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ pt: 2, pb: 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ m: 0 }}>
          <Stack direction="row" alignItems="center">
            <Button
              variant="text"
              startIcon={<Iconify icon="mi:close" />}
              onClick={onClose}
            >
              {t('common.close')}
            </Button>
          </Stack>
          <Stack direction="row" alignItems="center">
            <IconButton
              onClick={() => window.open(fileUrl as string, '_blank')}
            >
              <Iconify icon="mingcute:external-link-line" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, mb: 4, px: 4, minHeight: '60vh'}}>
          <DocumentViewer fileUrl={fileUrl as string} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}