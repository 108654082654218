import { useSnackbar } from 'notistack';
import { useMemo, useState, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Alert, Dialog, Avatar, Button, TextField, Typography, DialogTitle, Autocomplete, DialogContent } from '@mui/material';

import { useTranslate } from 'src/locales';
import { IJob } from 'src/services/jobs/jobs.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { generateCandidateInviteError } from 'src/services/jobs/jobs.utils';
import { useGetOrganizationCandidateQuery } from 'src/services/organisation/organisation.service';
import { useSendJobInviteMutation, useGetOrganisationJobsQuery } from 'src/services/jobs/jobs.service';

import FeatureLockedChip from 'src/components/billing/feature-locked-chip';

import { useFeatures } from 'src/sections/billing/usage/hooks/use-features';

import { TenantType } from 'src/types/enums';
import { FeatureType } from 'src/types/subscription.types';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  candidateId: string;
};

export default function AddCandidateJobModal({ candidateId, open, onClose }: Props) {
  const { t } = useTranslate();

  const { enqueueSnackbar } = useSnackbar();

  const tenantType = useOrgTenant();

  const { canAccess } = useFeatures();

  const [error, setError] = useState<string | null>(null);

  const [jobSearchQuery, setJobSearchQuery] = useState<string>('');

  const [selectedJob, setSelectedJob] = useState<IJob | null>(null);

  const { currentData: jobs } = useGetOrganisationJobsQuery({
    search_query: jobSearchQuery,
    page: 1,
    per_page: 5
  });

  const { currentData: orgCandidate, isLoading: isCandidateLoading } = useGetOrganizationCandidateQuery(candidateId, {
    skip: !candidateId
  });

  const [sendJobInvite, { isLoading: isSubmitting }] = useSendJobInviteMutation();

  const canAccessInviteFeature = useMemo(() => canAccess(FeatureType.Job_Invites), [canAccess]);

  const onCloseDialog = useCallback(() => {

    setSelectedJob(null);
    onClose();
  }, [onClose]);

  const handleInviteCandidate = async () => {
    try {

      if (!selectedJob || !orgCandidate) return;

      await sendJobInvite({
        jobId: (selectedJob as IJob).id,
        data: {
          email: orgCandidate.candidate_profile.email as string,
          first_name: orgCandidate.candidate_profile.first_name as string,
          last_name: orgCandidate.candidate_profile.last_name as string
        }
      }).unwrap();

      enqueueSnackbar(t('jobs.api.invite.success'), { variant: 'success' });

      setSelectedJob(null);
      onCloseDialog();
    } catch (e) {
      setError(generateCandidateInviteError(e));
      enqueueSnackbar(generateCandidateInviteError(e), { variant: 'error' });
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onCloseDialog}>
      <DialogTitle>{t(`candidates.invites.add_to_job_modal.title`)}</DialogTitle>
      <DialogContent>
        {!!error && <Alert severity="error">{error}</Alert>}
        <Grid container spacing={2} justifyContent="center" pt={1}>
          <Grid item xs={12}>
            <Alert variant="outlined" severity="info">
              {t(`candidates.invites.add_to_job_modal.alert`)}
            </Alert>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" gap={1} justifyContent="start" alignItems="center" my={3} px={1}>
              <Avatar variant="rounded"
                src={orgCandidate?.candidate_profile.profile_image?.public_path as string}
                alt={orgCandidate?.candidate_profile.first_name}
              />
              <Stack direction="column">
                <Typography variant="h6">
                  {orgCandidate?.candidate_profile.first_name} {orgCandidate?.candidate_profile.last_name}
                </Typography>
                <Typography variant="caption">
                  {orgCandidate?.candidate_profile.position_title}
                </Typography>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} mb={5}>
            <Autocomplete
              options={jobs?.results as IJob[] ?? []}
              value={selectedJob}
              disabled={!canAccessInviteFeature}
              onInputChange={(_event, value) => setJobSearchQuery(value)}
              onChange={(_event, value) => setSelectedJob(value)}
              renderOption={({ key, ...props }, option: IJob) => (
                <li key={key} {...props}>
                  <Stack direction="column">
                    <Typography variant="body1">
                      {`${option.title}`}
                    </Typography>

                    {
                      tenantType === TenantType.Recruiter && (
                        <Typography variant="caption">{option.client_company_name}</Typography>
                      )
                    }

                  </Stack>
                </li>
              )}
              getOptionLabel={(option) =>
                option ? `${option.title}` : ''
              }
              isOptionEqualToValue={(option: IJob, value: IJob) => option.id === value?.id}
              getOptionKey={(option) => (option as IJob).id as string}
              renderInput={(params) => (
                <TextField {...params} label={t('jobs.toolbar.search.placeholder')} />
              )}
              sx={{
                minWidth: 200,
              }}
            />
          </Grid>

          <Stack sx={{ py: 2, width: '100%' }} gap={1} flexDirection="row" justifyContent="flex-end" alignItems="center">

            <Button variant='text' onClick={onCloseDialog}>{t('common.cancel')}</Button>

            {
              canAccessInviteFeature ? (
                <LoadingButton
                  type="submit"
                  variant="contained"
                  onClick={handleInviteCandidate}
                  disabled={isSubmitting || !selectedJob || !orgCandidate}
                  loading={isSubmitting}
                >
                  {t('candidates.invites.add_to_job_modal.add')}
                </LoadingButton>
              ) : (
                <FeatureLockedChip text={t('common.upgrade')} />
              )
            }

          </Stack>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
