import { useState } from 'react';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Page, pdfjs, Document } from 'react-pdf';

import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import { CircularProgress } from '@mui/material';

import { useTranslate } from 'src/locales';



// Set the worker for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs` as string;

// ----------------------------------------------------------------------

interface Props {
  fileUrl: string;
}

export default function DocumentViewer({ fileUrl }: Props) {

  const { t } = useTranslate();

  const [pageCount, setPageCount] = useState<number | null>(null);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setPageCount(numPages);
  };

  const onDocumentLoadError = () => {
    setPageCount(null);
  }

  return (
    <Stack direction="column" position="relative" >
      <Box sx={{ m: 0, p: 0, overflowY: 'hidden' }}>
        <Document
          className="document-viewer-document"
          loading={
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
              <CircularProgress />
            </Box>
          }
          error={
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
              {t('documents.document_viewer.load_error')}
            </Box>
          }
          file={{ url: fileUrl }}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}

        >
          <Stack direction="column" alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
            {
              Array.from(new Array(pageCount || 0), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1} />
              ))
            }
          </Stack>
        </Document>
      </Box>
    </Stack>
  );
}
