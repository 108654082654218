import { isString } from 'lodash';

import { ClientApplicationStages } from 'src/utils/applications-kanban';

import i18n from 'src/locales/i18n';

import { TenantType } from 'src/types/enums';
import { BusinessErrorCodes } from 'src/types/business-errors';

import { applicationsApi } from './applications.service';
import { Application, RejectionReason, ApplicationStage } from './applications.types';

export const optimisticallyProgressCandidateApplicationStatus = (
  jobId: string,
  applicationId: string,
  stage: string
) =>
  applicationsApi.util.updateQueryData(
    'getJobCandidates',
    {
      jobId,
      params: {
        page: 1,
        per_page: 999,
      },
    },
    (applicationsResponse) => ({
      ...applicationsResponse,
      results: applicationsResponse.results.map((application: Application) =>
        application.id === applicationId
          ? { ...application, stage: stage as ApplicationStage }
          : application
      ),
    }),
    true
  );

export const isApplicationFinalized = (stage: ApplicationStage) =>
  [
    ApplicationStage.REJECTED,
    ApplicationStage.WITHDRAWN,
    ApplicationStage.TO_BE_REJECTED,
    ApplicationStage.OFFER_ACCEPTED,
  ].includes(stage);

export const getConfirmStageChangeContentMessage = (
  targetStage: ApplicationStage,
  tenantType: TenantType
) => {
  const targetStageLabel = i18n.t(`enums.application_status.${targetStage}`);

  if (targetStage === ApplicationStage.REJECTED) {
    return i18n.t('applications.stage_change.reject_application');
  }

  if (targetStage === ApplicationStage.SHORTLISTED && tenantType === TenantType.Recruiter) {
    return i18n.t('applications.stage_change.confirm_shortlist_recruiter');
  }

  if (targetStage === ApplicationStage.SHORTLISTED && tenantType === TenantType.Client) {
    return i18n.t('applications.stage_change.confirm_shortlist_client');
  }

  if (ClientApplicationStages.includes(targetStage) && tenantType === TenantType.Recruiter) {
    return i18n.t('applications.stage_change.confirm_alert_client', {
      target: targetStageLabel,
    });
  }

  if (ClientApplicationStages.includes(targetStage) && tenantType === TenantType.Client) {
    return i18n.t('applications.stage_change.confirm', {
      target: targetStageLabel,
    });
  }

  return i18n.t('applications.stage_change.confirm', {
    target: targetStageLabel,
  });
};

export const getConfirmStageChangeRequestContentMessage = (targetStage: ApplicationStage) => {
  const targetStageLabel = i18n.t(`enums.application_status.${targetStage}`);

  return i18n.t('applications.stage_change_request.confirm', {
    target: targetStageLabel,
  });
};

export const rejectionReasons = [
  {
    value: RejectionReason.NOT_GOOD_FIT,
    label: i18n.t('applications.rejection.reasons.not_good_fit'),
  },
  {
    value: RejectionReason.ROLE_CLOSED,
    label: i18n.t('applications.rejection.reasons.role_closed'),
  },
  {
    value: RejectionReason.NOT_ENOUGH_EXPERIENCE,
    label: i18n.t('applications.rejection.reasons.not_enough_experience'),
  },
  {
    value: RejectionReason.OTHER,
    label: i18n.t('applications.rejection.reasons.other')
  },
]

export const getUpdateStageErrorMessage = (e: any) => {
  if (e?.data?.error_code === BusinessErrorCodes.JobNotVerified) {
    return i18n.t('applications.api.update_stage.not_verified');
  }
  if (e?.data?.error_code === BusinessErrorCodes.ShortlistLimitReached) {
    return i18n.t('applications.api.update_stage.shortlist_limit_reached');
  }
  if (e?.data?.error_code === BusinessErrorCodes.ShortlistDeadlineExpired) {
    return i18n.t('applications.api.update_stage.shortlist_deadline_expired');
  }

  return i18n.t('applications.api.update_stage.default_error');
};

export const getShortlistCandidatesErrorMessage = (e: any) => {
  if (e?.data?.error_code === BusinessErrorCodes.JobNotVerified) {
    return i18n.t('applications.api.shortlist.not_verified');
  }
  if (e?.data?.error_code === BusinessErrorCodes.ShortlistLimitReached) {
    return i18n.t('applications.api.update_stage.shortlist_limit_reached');
  }
  if (e?.data?.error_code === BusinessErrorCodes.ShortlistDeadlineExpired) {
    return i18n.t('applications.api.update_stage.shortlist_deadline_expired');
  }

  return i18n.t('applications.api.shortlist.default_error');
};

export const getProgressToCandidateErrorMessage = (e: any) => i18n.t('applications.api.progress_to_candidate.default_error');

export const getRejectApplicationsErrorMessage = (e: any) => i18n.t('applications.api.reject.default_error');

export const getCreateOfferErrorMessage = (e: any) => {
  if (e?.data?.error_code === BusinessErrorCodes.NoVacanciesAvailable) {
    return i18n.t('applications.api.create_offer.no_vacancies');
  }

  if (e?.data?.error_code === BusinessErrorCodes.PendingOffersExist) {
    return i18n.t('applications.api.create_offer.offer_exists');
  }

  return i18n.t('applications.api.create_offer.default_error');
};

export const generateWithdrawApplicationError = (e: any) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.ApplicationHasFinalized) {
      return i18n.t('applications.api.withdraw.application_finalized');
    }
  }

  return i18n.t('applications.api.withdraw.default_error');
};
