import moment from 'moment';
import { isNumber } from 'lodash';
import { useMemo, useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Unstable_Grid2';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  Alert,
  Stack,
  Select,
  useTheme,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  CircularProgress,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { fToNow } from 'src/utils/format-time';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { rejectionReasons } from 'src/services/applications/applications.utils';
import { setHideActionStageRequestModal } from 'src/store/slices/applications/applicationsSlice';
import { useGetOrganizationSettingsQuery } from 'src/services/organisation/organisation.service';
import {
  ApplicationStage,
  StageChangeRequestAction,
} from 'src/services/applications/applications.types';
import {
  useActionStageChangeRequestMutation,
  useGetApplicationStageChangeRequestByIdQuery,
} from 'src/services/applications/applications.service';

import { ConfirmDialog } from 'src/components/custom-dialog';

type Props = {
  open: boolean;
};

export default function ActionStageChangeModal({ open }: Props) {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();

  const confirmDefer = useBoolean();

  const authContext = useAuthContext();

  const theme = useTheme();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const selectedRequestId = useAppSelector((state) => state.applications.selectedRequestId);

  const [rejectionReason, setRejectionReason] = useState<string | null>(null);
  const [rejectionFeedback, setRejectionFeedback] = useState<string | null>(null);

  const onClose = useCallback(() => {
    dispatch(setHideActionStageRequestModal());
  }, [dispatch]);

  const { currentData: stageChangeRequest, isLoading } =
    useGetApplicationStageChangeRequestByIdQuery(selectedRequestId as string, {
      skip: !selectedRequestId,
    });

  const [actionRequest, { isLoading: isActioning }] = useActionStageChangeRequestMutation();

  const { currentData: orgSettings, isLoading: isLoadingOrgSettings } =
    useGetOrganizationSettingsQuery(authContext.organization.id);

  useEffect(() => {
    if (stageChangeRequest && stageChangeRequest?.target_stage === ApplicationStage.REJECTED) {
      setRejectionReason(stageChangeRequest.rejection_context?.reason as string);
      setRejectionFeedback(stageChangeRequest.rejection_context?.feedback as string);
    }
  }, [stageChangeRequest]);

  const onActionRequest = useCallback(
    async (action: StageChangeRequestAction) => {
      setErrorMessage(null);
      try {
        await actionRequest({
          requestId: selectedRequestId as string,
          action,
          rejection_feedback: rejectionFeedback ?? undefined,
          rejection_reason: rejectionReason ?? undefined,
        }).unwrap();

        onClose();
      } catch (error) {
        setErrorMessage(t('applications.action_stage_change_modal.api.error'));
        console.error(t('applications.action_stage_change_modal.api.error'));
      }
    },
    [actionRequest, selectedRequestId, rejectionFeedback, rejectionReason, onClose, t]
  );

  const stopGapTime = useMemo(
    () =>
      !isLoadingOrgSettings
        ? orgSettings?.find((setting) => setting.setting_type === 'stop_gap_hours')?.setting_value
        : null,
    [isLoadingOrgSettings, orgSettings]
  );

  const stopGapDate = useMemo(
    () =>
      stopGapTime && isNumber(+stopGapTime) ? moment().add(+stopGapTime, 'hours').toDate() : null,
    [stopGapTime]
  );

  const renderRequest = (
    <Stack direction="column" spacing={2}>
      <Alert severity="info">
        {isLoading ? (
          <CircularProgress />
        ) : (
          t(`applications.action_stage_change_modal.alert`, {
            stage: t(`enums.application_status.${stageChangeRequest?.target_stage}`),
          })
        )}
      </Alert>

      <Grid
        container
        flexDirection="row"
        py={2}
        sx={{ backgroundColor: theme.palette.grey[200], borderRadius: 1 }}
      >
        <Grid xs={6} px={2}>
          <Typography variant="body1" fontWeight="bold">
            {`${t('common.candidate')} ${t('common.name')}`}
          </Typography>
          <Typography variant="body1">
            {stageChangeRequest?.application.attached_profile?.first_name}{' '}
            {stageChangeRequest?.application.attached_profile?.last_name}
          </Typography>
        </Grid>

        <Grid xs={6} px={2}>
          <Typography variant="body1" fontWeight="bold">
            {t(`applications.action_stage_change_modal.target_stage`)}
          </Typography>
          <Typography variant="body1">
            {t(`enums.application_status.${stageChangeRequest?.target_stage}`)}
          </Typography>
        </Grid>
      </Grid>

      {stageChangeRequest?.target_stage === ApplicationStage.REJECTED && (
        <Stack spacing={2}>
          <Stack>
            <InputLabel required>{t('applications.rejection.reason_label')}</InputLabel>
            <Select
              required
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
            >
              {rejectionReasons.map((reason) => (
                <MenuItem key={reason.value} value={reason.value}>
                  {reason.label}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack>
            <InputLabel>{t('applications.rejection.feedback_label')}</InputLabel>
            <TextField
              value={rejectionFeedback}
              onChange={(e) => setRejectionFeedback(e.target.value)}
              multiline
            />
          </Stack>
        </Stack>
      )}

      <Stack direction="row" spacing={2}>
        <LoadingButton
          disabled={isLoading || isLoadingOrgSettings}
          fullWidth
          variant="contained"
          color="error"
          onClick={confirmDefer.onTrue}
        >
          {t('common.defer')}
        </LoadingButton>
        <LoadingButton
          disabled={isLoading || isLoadingOrgSettings}
          onClick={() => onActionRequest(StageChangeRequestAction.Accept)}
          loading={isActioning}
          fullWidth
          variant="contained"
          color="success"
        >
          {t('common.approve')}
        </LoadingButton>
      </Stack>
    </Stack>
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>
        {t(`applications.action_stage_change_modal.title`)}
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%', typography: 'body1' }}>{renderRequest}</Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common.close')}</Button>
      </DialogActions>

      <ConfirmDialog
        open={confirmDefer.value}
        onClose={confirmDefer.onFalse}
        title={`${t('common.defer')} ${t('common.application')}`}
        content={
          <Alert severity="error">
            {t(`applications.action_stage_change_modal.confirm_defer`, {
              time_string: stopGapDate ? fToNow(stopGapDate, false) : '',
            })}
          </Alert>
        }
        action={
          <LoadingButton
            variant="contained"
            color="error"
            onClick={() => onActionRequest(StageChangeRequestAction.Defer)}
            loading={isActioning}
          >
            {t('common.confirm')}
          </LoadingButton>
        }
      />
    </Dialog>
  );
}
