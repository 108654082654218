import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Card, Link, IconButton, CardContent, useMediaQuery } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { fDate } from 'src/utils/format-time';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { openDocumentViewer } from 'src/store/slices/app/appSlice';
import { CandidateProfileDocument } from 'src/services/candidates/candidates.types';

import Iconify from 'src/components/iconify';
import FileThumbnail from 'src/components/file-thumbnail';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import TruncatedTextField from 'src/components/truncate-text/truncated-text-field';

import EditProfileDocumentModal from 'src/sections/profile/modals/edit-profile-document-modal';

// ----------------------------------------------------------------------

type Props = {
  row: CandidateProfileDocument;
  selected: boolean;
  onSelectRow: VoidFunction;
  onDeleteRow?: VoidFunction;
};

export default function ProfileDocumentTableRow({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    visibility_rule,
    expiry_date,
    document: { public_path, type, name, custom_type_name },
  } = row;

  const dispatch = useAppDispatch();

  const { t } = useTranslate();

  const details = useBoolean();

  const confirm = useBoolean();

  const popover = usePopover();

  const defaultStyles = {};

  const showEditModal = useBoolean();

  const onDocumentOpen = (item: CandidateProfileDocument) => {
    dispatch(openDocumentViewer({
      fileUrl: item.document.public_path,
    }))
  }

  if (isMobile) {
    return (
      <Card
        sx={{
          m: 2,
          mb: 2,
          ...(selected && {
            boxShadow: theme.customShadows.z20,
            bgcolor: 'background.paper',
          }),
        }}
      >
        <CardContent>
          <Stack spacing={2}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <FileThumbnail file={public_path} sx={{ width: 40, height: 40 }} />
              <Stack direction="column">
                <TruncatedTextField variant="subtitle1" noWrap text={name} limit={20} mode='characters' />
                <Typography variant="subtitle2" noWrap color={theme.palette.grey[400]}>
                  {t(`enums.document_type.${type}`)} {custom_type_name ? `(${custom_type_name})` : ''}
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="body2" color="text.secondary">
                {fDate(expiry_date)}
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="body2" color="text.secondary">
                {t(visibility_rule ? `enums.visibility_rule.${visibility_rule}` : '-')}
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Button
                LinkComponent={Link}
                href={public_path}
                target="_blank"
                variant="outlined"
                size="small"
              >
                {t('common.open')}
              </Button>
              {/* <Button
                size="small"
                color="error"
                onClick={() => {
                  confirm.onTrue();
                }}
              >
                {t('common.delete')}
              </Button> */}
              <Button
                size="small"
                onClick={() => {
                  showEditModal.onTrue();
                }}
              >
                {t('common.edit')}
              </Button>
            </Stack>
          </Stack>
        </CardContent>

        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title={t('common.delete')}
          content="Are you sure want to delete?"
          action={
            <Button variant="contained" color="error" onClick={onDeleteRow}>
              {t('common.delete')}
            </Button>
          }
        />

        <EditProfileDocumentModal
          open={showEditModal.value}
          onClose={showEditModal.onFalse}
          documentId={row.id}
        />
      </Card>
    );
  }

  return (
    <>
      <TableRow
        selected={selected}
        sx={{
          borderRadius: 2,
          [`&.${tableRowClasses.selected}, &:hover`]: {
            backgroundColor: 'background.paper',
            boxShadow: theme.customShadows.z20,
            transition: theme.transitions.create(['background-color', 'box-shadow'], {
              duration: theme.transitions.duration.shortest,
            }),
            '&:hover': {
              backgroundColor: 'background.paper',
              boxShadow: theme.customShadows.z20,
            },
          },
          [`& .${tableCellClasses.root}`]: {
            ...defaultStyles,
          },
          ...(details.value && {
            [`& .${tableCellClasses.root}`]: {
              ...defaultStyles,
            },
          }),
        }}
      >
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <FileThumbnail file={public_path} sx={{ width: 36, height: 36 }} />

            <Stack direction="column">
              <Typography
                variant="subtitle1"
                noWrap
                sx={{
                  maxWidth: 360,
                  cursor: 'pointer',
                  fontSize: 14,
                  ...(details.value && { fontWeight: 'bold' }),
                }}
              >
                {name ?? '-'}
              </Typography>
              <Typography variant="subtitle2" noWrap color={theme.palette.grey[500]}>
                {t(`enums.document_type.${type}`)} {custom_type_name ? `(${custom_type_name})` : ''}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {' '}
          <Typography variant="body2" color="text.secondary">
            {expiry_date ? fDate(expiry_date) : '-'}
          </Typography>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Typography variant="body2" color="text.secondary">
            {t(visibility_rule ? `enums.visibility_rule.${visibility_rule}` : '-')}
          </Typography>
        </TableCell>

        <TableCell>
          <Button variant="text" onClick={() => onDocumentOpen(row)}>
            {t('common.open')}
          </Button>
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-horizontal-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <EditProfileDocumentModal
        open={showEditModal.value}
        onClose={showEditModal.onFalse}
        documentId={row.id}
      />

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            showEditModal.onTrue();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          {t('common.edit')}
        </MenuItem>
      </CustomPopover>

      {/* <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            {t('common.delete')}
          </Button>
        }
      /> */}
    </>
  );
}
