import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { MobileDatePicker } from '@mui/x-date-pickers';
import {
  Grid,
  Alert,
  Stack,
  Button,
  Dialog,
  MenuItem,
  Typography,
  DialogTitle,
  DialogContent
} from '@mui/material';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { CandidateAvailabilityStatus } from 'src/services/candidates/candidates.types';
import {
  useUpdateAvailabilityMutation,
  useGetProfileAvailabilityQuery,
} from 'src/services/candidates/candidates.service';

import Iconify from 'src/components/iconify';
import { RHFSelect, RHFCheckbox } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

import { TenantType } from 'src/types/enums';


type Props = {
  open: boolean;
  onClose: VoidFunction;
};

export default function AvailabilityManagementModal({ open, onClose }: Props) {
  const { t } = useTranslate();

  const authContext = useAuthContext();

  const tenantType = useOrgTenant();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [updateAvailability] = useUpdateAvailabilityMutation();
  const { currentData } = useGetProfileAvailabilityQuery(undefined, {
    skip: tenantType !== TenantType.Candidate
  });

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [view, setView] = useState<'form' | 'subscriber'>('form');

  const availabilityManagementSchema = Yup.object().shape({
    status: Yup.string().required(
      t('profile.availability_management.modal.validation.status.required')
    ).not([currentData?.status], t('profile.availability_management.modal.validation.status.same')),
    available_from: Yup.date()
      .nullable()
      .when('status', ([status], schema) =>
        status === CandidateAvailabilityStatus.AVAILABLE_SOON
          ? Yup.date().required(
            t('profile.availability_management.modal.validation.available_from.required')
          )
          : schema
      ),
    withdraw_from_all: Yup.boolean().nullable(),
  });

  const defaultValues = {
    status: '',
    available_from: new Date(),
    withdraw_from_all: false,
  };

  const formMethods = useForm({
    resolver: yupResolver(availabilityManagementSchema),
    defaultValues,
    mode: 'all',
  });

  useEffect(() => {
    if (currentData) {
      formMethods.reset({
        status: currentData.status,
        available_from: currentData.available_from
          ? new Date(currentData.available_from)
          : new Date(),
        withdraw_from_all: false,
      });
    }
  }, [currentData, formMethods]);

  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isValid },
  } = formMethods;

  const selectedStatus = watch('status');
  const availableFrom = watch('available_from');

  const onCloseModal = () => {
    reset();
    onClose();
  };

  const submitForm = handleSubmit(async (data) => {
    try {
      await updateAvailability({
        status: data.status as CandidateAvailabilityStatus,
        available_from: data.status === CandidateAvailabilityStatus.AVAILABLE_SOON ? data.available_from : null,
        withdraw_from_all: data.withdraw_from_all ?? false,
      }).unwrap();

      enqueueSnackbar(t('profile.availability_management.modal.api.success'), {
        variant: 'success',
      });

      onClose();
    } catch (e) {
      console.error(e);
      enqueueSnackbar(t('profile.availability_management.modal.api.default_error'), {
        variant: 'error',
      });
    }
  });

  const manageAvailabilityAccess = () => {
    onCloseModal();
    navigate(paths.dashboard.connections.recruiters)
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onCloseModal}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{t(`profile.availability_management.modal.title`)}</Typography>

          <Button
            variant="outlined"
            color="inherit"
            size='small'
            startIcon={<Iconify icon="formkit:people" />}
            onClick={manageAvailabilityAccess}
            sx={{ textTransform: 'none' }}
          >
            {t(`profile.availability_management.modal.manage_access`)}
          </Button>

        </Stack>
      </DialogTitle>
      <DialogContent>
        {view === 'form' && (
          <FormProvider methods={formMethods} onSubmit={submitForm}>
            <Grid container spacing={2} justifyContent="center" px={1} py={1}>
              <Grid item xs={12} mb={3}>
                <Alert variant="outlined" severity="info">
                  {t(`profile.availability_management.modal.alert`)}
                </Alert>
              </Grid>

              <Grid item xs={12} pl={2}>
                <RHFSelect
                  name="status"
                  id="status_input"
                  label={t('profile.availability_management.modal.labels.status')}
                  InputLabelProps={{ shrink: true }}
                >
                  {Object.values(CandidateAvailabilityStatus).map((statusType: string) => (
                    <MenuItem key={statusType} value={statusType}>
                      {t(`enums.candidate_availability_status.${statusType}`)}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>

              {[CandidateAvailabilityStatus.AVAILABLE_SOON].includes(
                selectedStatus as CandidateAvailabilityStatus
              ) && (
                  <Grid item xs={12}>
                    <MobileDatePicker
                      orientation="portrait"
                      label={t('profile.availability_management.modal.labels.available_from')}
                      value={availableFrom}
                      onChange={(newValue) => {
                        setValue('available_from', newValue);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                        },
                      }}
                      minDate={new Date()}
                    />
                  </Grid>
                )}

              {(selectedStatus === CandidateAvailabilityStatus.UNAVAILABLE && currentData?.status !== CandidateAvailabilityStatus.UNAVAILABLE) && (
                <Grid item xs={12}>
                  <RHFCheckbox
                    label={t('profile.availability_management.modal.labels.withdraw_from_all')}
                    name="withdraw_from_all"
                  />
                </Grid>
              )}

              <Stack
                sx={{ py: 2, mt: 5, width: '100%' }}
                flexDirection="row"
                justifyContent="flex-end"
                spacing={2}
              >
                <Button variant="text" onClick={onCloseModal}>
                  {t('common.cancel')}
                </Button>
                <Button type="submit" variant="contained" disabled={!isValid}>
                  {t('common.update')}
                </Button>
              </Stack>
            </Grid>
          </FormProvider>
        )}

      </DialogContent>
    </Dialog>
  );
}
