import { useEffect, useCallback } from "react"

import { Stack } from "@mui/system"
import { Alert, Typography, CircularProgress } from "@mui/material"

import { useTranslate } from "src/locales"
import { useAppDispatch, useAppSelector } from "src/store/store"
import { updateBackgroundTaskState } from "src/store/slices/app/appSlice"
import { useGetBackgroundTasksQuery } from "src/services/system/system.service"
import { BackgroundTaskType, BackgroundTaskStatus } from "src/services/system/system.types"

export default function BackgroundTaskManager() {

  const { t } = useTranslate()

  const backgroundState = useAppSelector(state => state.app.background_task_state);
  const dispatch = useAppDispatch();

  const { data: backgroundTasks } = useGetBackgroundTasksQuery({}, {
    pollingInterval: backgroundState.pending_count > 0 ? 3000 : undefined
  });

  useEffect(() => {
    if (backgroundTasks) {
      const pendingTasks = backgroundTasks.filter(task => [BackgroundTaskStatus.PENDING, BackgroundTaskStatus.IN_PROGRESS].includes(task.status));
      const completedTasks = backgroundTasks.filter(task => task.status === BackgroundTaskStatus.COMPLETED);
      const failedTasks = backgroundTasks.filter(task => task.status === BackgroundTaskStatus.FAILED);

      dispatch(updateBackgroundTaskState({
        task_type: pendingTasks[0]?.type,
        total_count: backgroundTasks.length,
        pending_count: pendingTasks.length,
        completed_count: completedTasks.length,
        failed_count: failedTasks.length
      }))
    }
  }, [backgroundTasks, dispatch]);

  const getTaskText = useCallback(() => {

    if (!backgroundState.pending_count) return null;

    switch (backgroundState.task_type) {
      case BackgroundTaskType.FINALIZE_APPLY_INTENT:
        return t('system.background_tasks.info_labels.finalize_apply_intent', { completed: backgroundState.completed_count, total: backgroundState.total_count });
      default:
        return null;
    }

  }, [t, backgroundState]);

  if (!backgroundState.pending_count) return null;

  return (
    <Alert severity='info' sx={{ mb: 2, width: "100%" }} >
      <Stack direction='row' spacing={2} alignItems='center' justifyContent="space-between" sx={{ overflow: 'hidden' }}>
        <Typography variant='subtitle2' flexGrow={1}>
          {getTaskText()}
        </Typography>

        <CircularProgress size={16} color='info' />
      </Stack>
    </Alert>
  )
}