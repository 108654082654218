import { TenantType } from 'src/types/enums';

import { rootApi } from '../rootApi';
import { HTTPRequestType } from '../api.types';
import {
  Country,
  Category,
  SkillsResponse,
  GetSkillsProps,
  CategoryResponse,
  CurrencyResponse,
  SearchCategoryProps,
  SearchBusinessInfoProps,
  BusinessIDSearchResponse,
  BusinessNameSearchResponse,
} from './utils.types';

export interface TermsOfServiceResponse {
  id: string;
  content: string;
}

export interface RequestTermsOfServiceParams {
  type: string;
  target: TenantType;
  region: string;
}

export const utilsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query<Country[], void>({
      query: () => ({
        url: `/geo/countries`,
        method: HTTPRequestType.GET,
      }),
      transformResponse: (response: Country[]) => {
        const sortedCountries = response.sort((countryA, countryB) => {
          const name_a = countryA.name.toUpperCase();
          const name_b = countryB.name.toUpperCase();

          if (name_a < name_b) return -1;

          if (name_a > name_b) return 1;

          return 0;
        });

        return sortedCountries;
      },
    }),

    searchCategories: builder.query<CategoryResponse, SearchCategoryProps>({
      query: (data) => ({
        url: `/categories?name=${data.name}&page=${data.page}&per_page=${data.per_page}`,
      }),
      transformResponse: (response: CategoryResponse) => ({
        ...response,
        results: response.results.sort((a: Category, b: Category) => (a.name < b.name ? -1 : 1)),
      }),
    }),

    getCurrencies: builder.query<CurrencyResponse, void>({
      query: () => ({
        url: '/currencies',
      }),
    }),

    getSkills: builder.query<SkillsResponse, GetSkillsProps>({
      query: (params) => ({
        url: '/skills',
        params,
      }),
      providesTags: ['Skills'],
    }),

    searchBusinessInfoById: builder.query<BusinessIDSearchResponse, SearchBusinessInfoProps>({
      query: ({ query, countryCode }) => ({
        url: `/business-info/id-search`,
        method: HTTPRequestType.GET,
        params: {
          query,
          country: countryCode,
        },
      }),
    }),
    searchBusinessInfoByName: builder.query<BusinessNameSearchResponse, SearchBusinessInfoProps>({
      query: ({ query, countryCode }) => ({
        url: `/business-info/name-search`,
        method: HTTPRequestType.GET,
        params: {
          query,
          country: countryCode,
        },
      }),
    }),

  }),
});

export const {
  useGetCountriesQuery,
  useSearchCategoriesQuery,
  useLazySearchCategoriesQuery,
  useGetCurrenciesQuery,
  useGetSkillsQuery,

  useLazySearchBusinessInfoByIdQuery,
  useLazySearchBusinessInfoByNameQuery
} = utilsApi;
