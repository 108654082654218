import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';

import DataFetcher from 'src/utils/data-fetcher';

import { AuthGuard } from 'src/auth/guard';
import SentryErrorPage from 'src/pages/SentryErrorPage';
import SystemRoleGuard from 'src/auth/guard/system-role-guard';
import SystemDashboardLayout from 'src/layouts/system-dashboard';
import GlobalAccountsPage from 'src/pages/dashboard/restricted/accounts/global-accounts-page';
import GlobalSettingsPage from 'src/pages/dashboard/restricted/settings/system-settings-page';
import GlobalOrganizationsPage from 'src/pages/dashboard/restricted/organizations/global-organizations-page';

import { LoadingScreen } from 'src/components/loading-screen';
import JoyrideContainer from 'src/components/joyride/joyride-container';

import { UsageGuard } from 'src/sections/billing/usage';
import GlobalAccounts from 'src/sections/restricted/accounts/global-accounts';
import GlobalSettings from 'src/sections/restricted/settings/global-settings';
import GlobalOrganizations from 'src/sections/restricted/organizations/global-organizations';
import GlobalOrganizationDetail from 'src/sections/restricted/organizations/organization-detail';

// ----------------------------------------------------------------------

export const systemDashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <ErrorBoundary fallback={<SentryErrorPage />}>
        <AuthGuard>
          <UsageGuard>
            <SystemRoleGuard>
              <JoyrideContainer />
              <DataFetcher />
              <SystemDashboardLayout>
                <Suspense fallback={<LoadingScreen />}>
                  <Outlet />
                </Suspense>
              </SystemDashboardLayout>
            </SystemRoleGuard>
          </UsageGuard>
        </AuthGuard>
      </ErrorBoundary >
    ),
    children: [
      {
        path: 'restricted',
        element: <Outlet />,
        children: [
          {
            path: 'tenants',
            element: <GlobalOrganizationsPage />,
            children: [
              {
                index: true,
                element: <GlobalOrganizations />,
              },
              {
                path: ':org_id',
                element: <GlobalOrganizationDetail />,
              },
            ],
          },
          {
            path: 'accounts',
            element: <GlobalAccountsPage />,
            children: [
              {
                index: true,
                path: '',
                element: <GlobalAccounts />,
              },
            ],
          },
          {
            path: 'settings',
            element: <GlobalSettingsPage />,
            children: [
              {
                index: true,
                path: '',
                element: <GlobalSettings />,
              },
            ],
          },
        ],
      },
    ],
  },
];
