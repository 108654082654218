import { debounce } from 'lodash';
import { useMemo, useState, useCallback, ChangeEvent } from 'react';

import { Card } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { PageOptions } from 'src/services/api.types';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { DocumentType } from 'src/services/documents/documents.types';
import { useGetOrganisationDocumentsQuery } from 'src/services/documents/documents.service';

import EmptyContent from 'src/components/empty-content';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import { useTable, TableSkeleton } from 'src/components/table';

import DocumentsToolbar from 'src/sections/admin/documents/documents-toolbar';
import DocumentsUploader from 'src/sections/admin/documents/documents-uploader';

import FileManagerTable from '../../../../components/documents/file-manager-table';

// ----------------------------------------------------------------------

const DEFAULT_PAGE_OPTIONS: PageOptions = {
  page: 1,
  per_page: 10,
};

// ----------------------------------------------------------------------

export default function OrganizationDocumentsView() {
  const { t } = useTranslate();

  const authContext = useAuthContext();

  const table = useTable({ defaultRowsPerPage: 10 });

  const settings = useSettingsContext();

  const confirm = useBoolean();

  const onOpenCreateForm = useBoolean();

  const [filterQuery, setFilterQuery] = useState<string | undefined>();
  const [filterType, setFilterType] = useState<DocumentType | undefined>();

  const [currentPage, setCurrentPage] = useState<PageOptions>(DEFAULT_PAGE_OPTIONS);

  const { currentData: documentResponse, isFetching: isGettingDocuments } = useGetOrganisationDocumentsQuery({
    search_query: filterQuery,
    type: filterType,
    organization_id: authContext.organization.id,
    ...currentPage,
  }, {
    skip: !authContext?.organization?.id,
  });

  const notFound = !documentResponse?.results.length && !isGettingDocuments;

  const handleFilters = useMemo(
    () => debounce((filter, value: string | DocumentType) => {
      if (filter === 'type') {
        setFilterType(value as DocumentType);
      } else {
        setFilterQuery(value as string);
      }
    }, 500),
    [setFilterQuery]
  );

  const clearFilters = useCallback(() => {
    setFilterQuery('');
    setFilterType(undefined);
  }, []);

  const onChangePage = useCallback((_event: any, page: number) => {
    setCurrentPage((prev) => ({
      ...prev,
      page: page + 1,
    }));
  }, []);

  const onChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setCurrentPage((prev) => ({
        ...prev,
        per_page: +event.target.value,
      }));
    },
    []
  );

  const handleDeleteItem = useCallback((id: string) => {
    // TODO: Implement when we get a backend api.
  }, []);

  const handleDeleteItems = useCallback(() => {
    // TODO: Implement when we get a backend api.
  }, []);

  const onDocumentCreated = useCallback(() => {
    onOpenCreateForm.onFalse();
  }, [onOpenCreateForm]);

  return (
    <>
      <Card>
        {
          isGettingDocuments ? (
            <TableSkeleton />
          ) :
            <>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <DocumentsToolbar
                  filters={{ type: filterType, search_query: filterQuery }}
                  onFilters={handleFilters}
                  onOpenCreateForm={onOpenCreateForm}
                  clearFilters={clearFilters}
                />
              </Stack>

              {notFound ? (
                <EmptyContent
                  filled
                  title="No Data"
                  sx={{
                    m: 2,
                    py: 10
                  }}
                />
              ) : (
                <FileManagerTable
                  table={table}
                  dataFiltered={documentResponse?.results ?? []}
                  count={documentResponse?.count ?? 1}
                  onDeleteRow={handleDeleteItem}
                  notFound={notFound}
                  pageOptions={currentPage}
                  onChangePage={onChangePage}
                  onChangePerPage={onChangeRowsPerPage}
                />
              )}

            </>
        }
      </Card>

      <DocumentsUploader open={onOpenCreateForm.value} onClose={onOpenCreateForm.onFalse} onSuccess={onDocumentCreated} />

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteItems();
              confirm.onFalse();
            }}
          >
            {t('common.delete')}
          </Button>
        }
      />
    </>
  );
}
