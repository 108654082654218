import mixpanel from 'mixpanel-browser';
import { useCallback, useMemo } from 'react';

import { localStorageGetItem } from '../local-storage';
import { TrackingEvent } from './tracking.types';

mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
  debug: false,
  track_pageview: false,
  persistence: 'localStorage'
});

export function useMixpanel() {

  const shouldTrack = useMemo(() => {
    // check auth status
    const accessToken = localStorageGetItem('access_token', undefined);
    
    return ['prod', 'staging'].includes(import.meta.env.VITE_NODE_ENV) && !!accessToken;

  }, []);

  const trackPageView = useCallback((properties: any) => {
    if (!shouldTrack) return;

    mixpanel.track_pageview(properties);
  }, [shouldTrack]);

  const trackEvent = useCallback((eventName: TrackingEvent, properties: any) => {
    if (!shouldTrack) return;

    mixpanel.track(eventName, properties);
  }, [shouldTrack]);

  const identify = useCallback((userId: any, userData: any) => {
    if (!shouldTrack) return;

    mixpanel.identify(userId);

    if (userData) {
      mixpanel.people.set(userData);
    }
  }, [shouldTrack]);

  return { trackEvent, trackPageView, identify };
}