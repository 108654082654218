import { IResource } from 'src/services/integration/integrations.types';

import { PlaceType } from 'src/components/hook-form/rhf-maps-autocomplete';

import { TenantType } from 'src/types/enums';

// eslint-disable-next-line import/no-cycle
import { Category } from '../utils/utils.types';
import { PageOptions, PaginatedResponse } from '../api.types';
import { Organization, ProfileOrganization } from '../auth/auth.types';
import { User, Department, CompanySizeRange } from '../organisation/organisation.types';
import { ApplicationStage, RejectionContext } from '../applications/applications.types';
import {
  RequestStatus,
  CompanyProfile,
  CompanyProfileType,
} from '../connections/connections.types';

export type JobsResponse = PaginatedResponse<IJob>;

export type JobFilters = {
  search_query?: string;
  job_status?: JobStatus;
  assigned_account?: string;
  page?: number;
  per_page?: number;
  verification_status?: JobVerificationStatus;
  application_status?: ApplicationStage;
  linked_application?: boolean;
};

export type GetJobsRequestProps = JobFilters;

export type GetJobBoardRequestProps = {
  board_id: string;
  page: number;
  per_page: number;
  search_query?: string;
  job_type?: JobType;
  employment_type?: EmploymentType;
  work_style?: WorkStyleType;
  industry?: string;
};

export enum ApplyFlowType {
  FORM_APPLY = 'form_apply',
  ACCOUNT_APPLY = 'account_apply'
}

export type ApplyFlowFormData = {
  first_name: string;
  last_name: string;
  email: string;
  phone?: string;
  resume_id: string;
  cover_letter_id?: string;
};

export type ApplyFlowAccountData = {
  account_id: string;
  resume_id: string;
  cover_letter_id?: string;
};

export type InitiateApplyFlowRequest = {
  board_id: string;
  job_id: string;
  flow_type: ApplyFlowType;
  form_data?: ApplyFlowFormData;
  account_data?: ApplyFlowAccountData;
};

export enum ApplyResultType {
  VERIFICATION_PENDING = 'verification_pending',
  APPLIED = 'applied',
}

export interface IApplyResultResponse {
  result_type: ApplyResultType;
  job_application_id?: string;
  candidate_job_id?: string;
}

export interface IApplyIntentResponse {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  job: {
    id: string;
    title: string;
  }
}

export interface IMinimalJobBoard {
  id: string;
  organization: ProfileOrganization;
  theme_settings?: {
    primary_color?: string;
    secondary_color?: string;
  }
}

export interface IJobBoard {
  id: string;
  embed_code: string;
  link: string;
  organization: ProfileOrganization;
}

export interface IJob {
  id: string;
  title: string;
  description: string;
  excerpt: string;
  latest_application_status: ApplicationStage;
  work_style?: WorkStyleType;
  job_type?: JobType;
  employment_type?: EmploymentType;
  recruitment_type?: JobRecruitmentType;
  start_date?: string;
  duration_period?: PeriodType;
  duration_value?: number;
  minimum_salary?: number;
  maximum_salary?: 120000;
  rate_period?: PeriodType;
  pay_rate?: number;
  charge_rate?: number;
  fee_type?: FeeType;
  fee_value?: number;
  fee_percentage?: number;
  shortlist_candidate_limit: number | null;
  currency?: {
    id: string;
    name: string;
    code: string;
  };
  vacancies?: number;
  reference?: string;
  role_status?: RoleStatus;
  client_company_name?: string;
  client_id?: string;
  client_contact_email?: string;
  client_contact_name?: string;
  recruiter_contact_email?: string;
  recruiter_contact_name?: string;
  recruiter_company_name?: string;
  industry?: Category;
  workplace_locations?: PlaceType[];
  required_skills?: Skill[];
  assigned_account?: Partial<User>;
  client?: {
    id: string;
    type: TenantType;
    company_name: string;
  };
  client_profile: CompanyProfile;
  recruiter_profile: CompanyProfile;
  verification_status?: JobVerificationStatus;
  status?: JobStatus;
  sections: JobSection[];
  created_at: Date;
  thread: {
    id: string;
  };
  source_type: SourceType;
  source_url: string;
  apply_url: string;
  excitement_rating: number;
  documents_available: boolean;
  comments_available: boolean;
  candidate_count?: number;
  shortlist_deadline_at: Date;
  integrations: IResource[];
  application?: {
    id?: string;
    stage: ApplicationStage;
    rejection_context?: RejectionContext;
    created_at: Date;
  };
  departments?: Department[];
  update_request_notes?: string;
  job_board_availability?: JobBoardAvailability;
  updated_at: Date;
}

export type JobSummary = {
  documents: number;
  applications: {
    count: number;
    candidates: number;
    applicants: number;
    stages: {
      [key: string]: number;
    };
  };
  stakeholders: number;
  placements: number;
  shortlist_deadline_at?: Date;
  shortlist_candidate_limit?: number;
  vacancies?: number;
  role_status?: RoleStatus;
  invites: {
    pending: number;
  };
  offers?: {
    count: number;
    status: {
      [key: string]: number;
    };
  };
  integrations?: number;
};

export interface LinkedAgency {
  id: string;
  company_name: string;
  company_type: TenantType;
  company_information: string;
  organization?: Pick<Organization, 'id' | 'status' | 'type'>;
  type: CompanyProfileType;
  company_size?: CompanySizeRange;
  company_logo?: {
    id: string;
    public_path: string;
  } | null;
  submitted_count: number;
  shortlist_candidate_limit: number | null;
  fee_type: FeeType;
  fee_value: number;
}

export interface CreateJobDTO {
  id?: string;
  title: string;
  description: string;
  excerpt?: string;
  default_application_status?: ApplicationStage;
  work_style?: WorkStyleType;
  job_type?: JobType;
  employment_type?: EmploymentType;
  recruitment_type?: JobRecruitmentType;
  start_date?: string;
  duration_period?: PeriodType;
  duration_value?: number;
  minimum_salary?: number;
  maximum_salary?: number;
  rate_period?: PeriodType;
  pay_rate?: number;
  charge_rate?: number;
  fee_type?: FeeType;
  fee_value?: number;
  fee_percentage?: number;
  currency_code?: string;
  vacancies?: number;
  reference?: string;
  role_status?: RoleStatus;
  industry_id?: string;
  locations?: PlaceType[];
  skills?: Partial<Skill>[];
  assigned_account?: string;
  client_id?: string;
  client_contact_email?: string;
  client_contact_name?: string;
  client_company_name?: string;
  recruiter_contact_email?: string;
  recruiter_contact_name?: string;
  recruiter_company_name?: string;
  verification_status?: JobVerificationStatus;
  status?: JobStatus;
  sections?: JobSection[];
  submit_behaviour: string;
  source_url?: string;
  excitement_rating?: number;
  departments?: string[];
}

export type UpdateRoleDTO = {
  status: RoleStatus;
  vacancies: number;
  shortlist_deadline_at: string | null;
};

export type UpdateRoleRequestProps = {
  jobId: string;
  data: UpdateRoleDTO;
};

export enum JobRecruitmentType {
  CONTINGENT = 'contingent',
  EXCLUSIVE = 'exclusive',
  INTERNAL = 'internal',
}

export enum JobStatus {
  DRAFT = 'draft',
  UNAPPROVED = 'unapproved',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  CHANGES_REQUESTED = 'changes_requested',
}

export enum SubmitBehaviour {
  SUBMIT = 'submit',
  SAVE_AS_DRAFT = 'save_as_draft',
  SAVE = 'save',
}

export enum JobVerificationStatus {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
  PENDING = 'pending',
}

export enum JobBoardAvailability {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
}

export enum EmploymentType {
  PERMANENT = 'permanent',
  FIXED_TERM = 'fixed_term',
  CONTRACT = 'contract',
}

export enum FeeType {
  PERCENTAGE = 'percentage',
  FIXED = 'fixed',
}

export enum FeeSettingsSourceType {
  DEFAULT = 'default',
  CUSTOM = 'custom',
}

export enum SourceType {
  DASHBOARD = 'dashboard',
  CLONE = 'clone',
  JOB_ADDER = 'job_adder',
}

export enum RoleStatus {
  LIVE = 'live',
  ON_HOLD = 'on_hold',
  CLOSED = 'closed',
}

export enum WorkStyleType {
  Hybrid = 'hybrid',
  Remote = 'remote',
  Onsite = 'onsite',
}

export enum JobType {
  FULL_TIME = 'full_time',
  PART_TIME = 'part_time',
  CASUAL = 'casual',
}

export enum PeriodType {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export type Skill = {
  id?: string;
  name: string;
  creator_type?: SkillCreatorType;
};

export enum SkillCreatorType {
  SYSTEM = 'system',
  TENANT = 'tenant',
}

export interface JobSection {
  type: JobSectionType;
  visibility_rule: VisibilityRule;
}

export enum JobSectionType {
  TITLE = 'title',
  DESCRIPTION = 'description',
  EXCERPT = 'excerpt',
  RECRUITMENT_TYPE = 'recruitment_type',
  WORK_STYLE = 'work_style',
  EMPLOYMENT_TYPE = 'employment_type',
  JOB_TYPE = 'job_type',
  INDUSTRY = 'industry',

  LOCATIONS = 'locations',
  SKILLS = 'skills',
  DOCUMENTS = 'documents',

  DATE_INFORMATION = 'date_information',

  COMPENSATION = 'compensation',

  RECRUITER_FEES = 'recruiter_fees',

  CLIENT = 'client',
  RECRUITER = 'recruiter',
}

export enum VisibilityRule {
  PUBLIC = 'public',
  RESTRICTED = 'restricted',
  HIDDEN = 'hidden',
}

export enum FeeTypeDisplay {
  Fixed = '$',
  Percentage = '%',
}

export interface LinkFeeSettingsDto {
  source_type: FeeSettingsSourceType;
  custom_fees?: {
    fee_value: number;
    fee_type: FeeType;
  };
}

export interface LinkSettingsDto {
  shortlist_candidate_limit: number | null;
  fee_value: number;
  fee_type: FeeType;
}

export interface RequestJobLink {
  job_id: string;
  data: {
    target_id: string;
    notes?: string | null;
    fee_settings?: LinkFeeSettingsDto;
    shortlist_candidate_limit?: number | null;
  };
}

export interface JobLinkRequest {
  id: string;
  source_job: IJob;
  status: JobLinkStatus;
  source_organization: CompanyProfile;
}

export type GetJobLinkRequestsProps = PageOptions & {
  status: JobLinkStatus;
};

export enum JobLinkStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export enum JobLinkAction {
  ACCEPT = 'accept',
  REJECT = 'reject',
}

export enum JobInviteAction {
  ACCEPT = 'accept',
  REJECT = 'reject',
}

export enum ImportWorkflowType {
  LINK = 'link',
  CONTENT = 'content',
}

export enum StakeholderLabel {
  HIRING_MANAGER = 'hiring_manager',
  DECISION_MAKER = 'decision_maker',
  INTERNAL_TALENT = 'internal_talent',
  EXECUTIVE = 'executive',
  ADVISOR = 'advisor',
  LEGAL = 'legal',
  HR = 'hr',

  OBSERVER = 'observer',

  CONSULTANT = 'consultant',
  ACCOUNT_MANAGER = 'account_manager',
  TEAM_MANAGER = 'team_manager',
  RESOURCER = 'resourcer',
  ADMINISTRATION = 'administration',

  CANDIDATE = 'candidate',
}

export interface JobLinkActionRequestProps {
  requestId: string;
  data: JobLinkActionData;
}

export interface JobInviteActionRequestProps {
  requestId: string;
  data: JobInviteActionData;
}

export interface ImportJobProps {
  data: {
    workflow_type: ImportWorkflowType;
    url?: string | null;
    content?: string | null;
  };
}

export interface JobInviteActionData {
  action: JobInviteAction;
  link_settings?: {
    job_id?: string;
    link_behaviour: LinkBehaviour;
  };
}

export interface JobLinkActionData {
  link_settings?: {
    job_id?: string;
    link_behaviour: LinkBehaviour;
    create_params?: CreateJobDTO;
    shortlist_candidate_limit?: number | null;
  };
  fee_settings?: LinkFeeSettingsDto;
}

export enum LinkBehaviour {
  CLONE_SOURCE = 'clone_source',
  LINK_EXISTING = 'link_existing',
  CREATE_AND_LINK = 'create_and_link',
}

export type GetLinkedAgenciesProps = {
  pageOptions: PageOptions;
  jobId: string;
};

export type UpdateApplicationStatusProps = {
  jobId: string;
  status: ApplicationStage;
};
export type SendCandidateInviteProps = {
  jobId: string;
  data: {
    email: string;
    first_name: string;
    last_name: string;
  };
};

export type JobInvite = {
  id: string;
  status: RequestStatus;
  created_at: string;
  job: Partial<IJob>;
  initiator: CompanyProfile;
  first_name: string;
  last_name: string;
  email: string;
};

export type JobImportResponse = {
  job_id: string;
};

export type JobChecklistItem = {
  id: string;
  title: string;
  description: string;
  action_url: string;
  checked: boolean;
};

export type JobChecklistResponse = {
  id: string;
  items: JobChecklistItem[];
};

export type UploadJobDocumentProps = {
  jobId: string;
  document_id: string;
};

// stakeholder types

export interface StakeholderLabelResponse {
  id: string;
  label: string;
}

export type JobStakeholderResponse = {
  account: User;
  labels: StakeholderLabelResponse[];
  visibility: VisibilityRule;
};

export interface AssignStakeholderRequestProps {
  job_id: string;
  account_id: string;
  labels: StakeholderLabel[];
  visibility: VisibilityRule;
}

export interface UpdateStakeholderRequestProps {
  job_id: string;
  account_id: string;
  labels: StakeholderLabel[];
  visibility: VisibilityRule;
}

export interface RemoveStakeholderRequestProps {
  job_id: string;
  account_id: string;
}

export type GetJobStakeholdersRequestProps = {
  job_id: string;
  page: number;
  per_page: number;
  search_query?: string;
};

export const ClientStakeholderLabels = [
  StakeholderLabel.HIRING_MANAGER,
  StakeholderLabel.DECISION_MAKER,
  StakeholderLabel.INTERNAL_TALENT,
  StakeholderLabel.EXECUTIVE,
  StakeholderLabel.ADVISOR,
  StakeholderLabel.LEGAL,
  StakeholderLabel.HR,
  StakeholderLabel.OBSERVER,
];

export const RecruiterStakeholderLabels = [
  StakeholderLabel.CONSULTANT,
  StakeholderLabel.ACCOUNT_MANAGER,
  StakeholderLabel.TEAM_MANAGER,
  StakeholderLabel.RESOURCER,
  StakeholderLabel.ADMINISTRATION,
  StakeholderLabel.OBSERVER,
];

export const CandidateStakeholderLabels = [StakeholderLabel.CANDIDATE];
