import * as Yup from 'yup';
import { m } from 'framer-motion';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
// eslint-disable-next-line import/no-extraneous-dependencies
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Link,
  Alert,
  Stack,
  useTheme,
  IconButton,
  Typography,
  FormHelperText,
  InputAdornment,
  CircularProgress
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { EmailRegex } from 'src/utils/data/regex';

import { logOut } from 'src/auth/utils';
import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { CompleteApplyIntentDTO } from 'src/services/auth/auth.types';
import { TermsType } from 'src/services/connections/connections.types';
import { useGetApplyIntentQuery } from 'src/services/jobs/jobs.service';
import { useCompleteApplyIntentMutation } from 'src/services/auth/auth.service';
import {
  useGetTenantTermsQuery,
  RequestTermsOfServiceParams,
} from 'src/services/system/system.service';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { varFade, MotionContainer } from 'src/components/animate';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

import TermsModal from 'src/sections/auth/register/modals/terms-modal';

import { TenantType } from 'src/types/enums';

import { generateCompleteApplyIntentErrorMessage } from '../../external/job-boards/job-board.utils';

export default function CompleteApplyIntentView() {
  const { t } = useTranslate();

  const router = useRouter();

  const theme = useTheme();

  const settings = useSettingsContext();

  const showPassword = useBoolean();

  const showTermsModal = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();

  const searchParams = useSearchParams();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const applyIntentToken = searchParams.get('apply-intent');

  const { currentData: applyIntent, isLoading: loadingApplyIntent, isError: applyIntentError } = useGetApplyIntentQuery(
    {
      token: applyIntentToken as string
    },
    {
      skip: !applyIntentToken

    });

  useEffect(() => {
    logOut();
  }, []);

  const [registerFromApplyIntent, { isLoading: isSubmittingIntent }] = useCompleteApplyIntentMutation();

  const { currentData: terms } = useGetTenantTermsQuery(({
    type: TermsType.TERMS_OF_USE,
    target: TenantType.Candidate,
    // region: moment.locale(), // TODO: Region locks
  } as RequestTermsOfServiceParams));

  const getPageTitle = (): string => t('auth.complete-apply-intent.title');

  const CompleteApplyIntentSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('auth.validation.email.required'))
      .matches(EmailRegex, t('auth.validation.email.valid')),
    firstName: Yup.string().required(t('auth.validation.firstName.required')),
    lastName: Yup.string().required(t('auth.validation.lastName.required')),
    password: Yup.string()
      .min(8, t('auth.validation.password.min'))
      .required(t('auth.validation.password.required')),
    repeatPassword: Yup.string()
      .test(
        'password-match',
        t('auth.validation.password.match'),
        (value, context) => context.parent.password === value
      )
      .required(t('auth.validation.password.repeat')),
    recaptcha_token: Yup.string().required(t('validation.required')),
    timezone: Yup.string(),
  });

  const defaultAccountValues = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    repeatPassword: '',
    accountType: '',
    recaptcha_token: import.meta.env.VITE_NODE_ENV === 'prod' ? '' : 'test-recaptcha-token',
    timezone: moment.tz.guess(),
  };

  const accountFormMethods = useForm({
    resolver: yupResolver(CompleteApplyIntentSchema),
    defaultValues: defaultAccountValues,
    mode: 'all',
  });

  const {
    handleSubmit: handleAccountSubmit,
    formState: { isSubmitting: accountSubmitting, isValid: accountFormIsValid, errors },
    setValue,
  } = accountFormMethods;

  useEffect(() => {
    if (!applyIntent) return;

    setValue('email', applyIntent.email);
    setValue('firstName', applyIntent.first_name);
    setValue('lastName', applyIntent.last_name);

  }, [applyIntent, setValue, t]);

  const onCompleteIntent = handleAccountSubmit(async (data) => {
    const { email, firstName, lastName, password, recaptcha_token, timezone } = data;

    setErrorMessage(null);

    if (!terms?.id || !applyIntentToken) {
      enqueueSnackbar(t('auth.api.register.metadata_error'), {
        variant: 'error',
      });

      return;
    }

    const body: CompleteApplyIntentDTO = {
      email,
      first_name: firstName,
      last_name: lastName,
      password,
      terms_id: terms?.id,
      recaptcha_token,
      apply_intent_token: applyIntentToken as string,
      timezone: timezone as string,
    }

    try {
      // await dispatch(authApi.util.resetApiState());
      await registerFromApplyIntent(body).unwrap();

      enqueueSnackbar(t('auth.api.register.success'), {
        variant: 'success',
      });

      router.push(paths.dashboard.root);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(generateCompleteApplyIntentErrorMessage(e), {
        variant: 'error',
      });

      setErrorMessage(generateCompleteApplyIntentErrorMessage(e));
    }
  });

  const renderErrorAlert = () => (
    <Alert severity="error" sx={{ marginBottom: theme.spacing(2) }}>
      <Stack direction="column">
        <Stack>{t('auth.complete-apply-intent.error.default')}</Stack>
      </Stack>
    </Alert>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{
        textAlign: 'center',
        typography: 'caption',
        color: 'text.secondary',
      }}
    >
      {t('auth.register.terms.agree')}{' '}
      <Link
        underline="always"
        color="text.primary"
        sx={{ cursor: 'pointer' }}
        onClick={showTermsModal.onTrue}
      >
        {t('auth.register.terms.tos')}
      </Link>
      .
    </Typography>
  );

  const renderApplyIntent = () => (
    <m.div key="apply-intent" variants={varFade().inUp}>
      <Box textAlign="center">
        <Iconify
          icon="solar:verified-check-bold"
          className="text-blue-500 drop-shadow-lg"
          sx={{ height: 96, width: 96 }}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.background.neutral,
          padding: 3,
          boxShadow: theme.customShadows.z1,
          borderRadius: 2,
        }}
      >
        <Typography variant="h4">{applyIntent?.job.title}</Typography>
        <Typography variant="body1">
          {t('auth.complete-apply-intent.header_message', {
            job_title: applyIntent?.job.title,
          })}
        </Typography>
      </Box>
    </m.div>
  );

  const renderAccountForm = () => (
    <FormProvider methods={accountFormMethods} onSubmit={onCompleteIntent}>
      <MotionContainer>
        <m.div
          key="more-form"
          variants={varFade().inUp}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Grid container spacing={2.5} sx={{ marginTop: theme.spacing(0.5) }}>
            <Grid item xs={12}>
              <RHFTextField
                name="email"
                fullWidth
                disabled
                label={t('auth.form-labels.email')}
                inputProps={{ 'data-testid': 'email-input' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFTextField
                name="firstName"
                fullWidth
                inputProps={{ 'data-testid': 'first-name-input' }}
                label={t('auth.form-labels.first-name')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFTextField
                fullWidth
                name="lastName"
                inputProps={{ 'data-testid': 'last-name-input' }}
                label={t('auth.form-labels.last-name')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFTextField
                name="password"
                label={t('auth.form-labels.password')}
                type={showPassword.value ? 'text' : 'password'}
                fullWidth
                inputProps={{ 'data-testid': 'password-input' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={showPassword.onToggle} edge="end" tabIndex={-1}>
                        <Iconify
                          icon={showPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFTextField
                fullWidth
                name="repeatPassword"
                label={t('auth.form-labels.repeat-password')}
                type={showPassword.value ? 'text' : 'password'}
                inputProps={{ 'data-testid': 'repeat-password-input' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={showPassword.onToggle} edge="end" tabIndex={-1}>
                        <Iconify
                          icon={showPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Stack direction="column" alignItems="center">
                <ReCAPTCHA
                  sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
                  onChange={(value) =>
                    setValue('recaptcha_token', value ?? '', { shouldValidate: true })
                  }
                />
                {errors.recaptcha_token && (
                  <FormHelperText error>{errors.recaptcha_token.message}</FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="column" alignItems="center" gap={1}>
                <LoadingButton
                  color="inherit"
                  size="large"
                  type="submit"
                  fullWidth
                  variant="contained"
                  loading={isSubmittingIntent}
                  data-testid="submit-account-btn"
                  data-custom={accountFormIsValid ? 'valid' : 'not'}
                  disabled={!accountFormIsValid}
                >
                  {t('auth.complete-apply-intent.submit')}
                </LoadingButton>
                {renderTerms}
              </Stack>
            </Grid>
          </Grid>
        </m.div>
      </MotionContainer>

      <TermsModal
        open={showTermsModal.value}
        onClose={showTermsModal.onFalse}
        terms={terms?.content}
      />
    </FormProvider>
  );

  return (
    <Box padding={0}>
      {(applyIntentToken && loadingApplyIntent) ? (
        <m.div key="loading" variants={varFade().inUp} style={{ width: 320, height: 410 }}>
          <Stack spacing={3} alignItems="center" justifyContent="center" height="100%">
            <CircularProgress className="text-blue-500" />
          </Stack>
        </m.div>
      ) : (
        <>
          {
            (applyIntent && !applyIntentError) ?
              <>
                {renderApplyIntent()}
                {renderAccountForm()}
              </>
              :
              <m.div key="token-alert" variants={varFade().inUp}>
                {renderErrorAlert()}
              </m.div>
          }
        </>
      )}

      <Box sx={{ mt: 1 }}>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </Box>
    </Box>
  );
}
